import React, { Component } from 'react';
import { ModalBase, IconButton, Button } from 'joy-ui/components';
import { CloseIcon } from 'joy-ui/icons';

import styles from './DialogTemplate.module.scss';

interface IProps {
  children?: React.ReactNode;
  open: boolean;
  yesLabel?: string;
  noLabel?: string;
  onClose: () => void;
  onResponse: (response: boolean) => void;
}

class DialogTemplate extends Component<IProps> {
  handleNegativeAction = () => {
    this.props.onClose();
    this.props.onResponse(false);
  };

  handlePositiveAction = () => {
    this.props.onClose();
    this.props.onResponse(true);
  };

  render() {
    const { children, open, yesLabel, noLabel, onClose } = this.props;

    if (!open) {
      return null;
    }

    return (
      <ModalBase>
        <div className={styles.wrapper}>
          <div className={styles.card}>
            <IconButton className={styles.closeButton} icon={CloseIcon} color="muted" onClick={onClose} />

            {children}

            <div className={styles.cardActions}>
              <Button color="error" onClick={this.handlePositiveAction}>
                {yesLabel || 'Yes'}
              </Button>

              <Button variant="outlined" color="muted" onClick={this.handleNegativeAction}>
                {noLabel || 'No'}
              </Button>
            </div>
          </div>
        </div>
      </ModalBase>
    );
  }
}

export default DialogTemplate;
