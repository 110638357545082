import React, { Component } from 'react';
import { JoyAlbum } from 'joy-core';
import { Text, AddIcon, PhotoIcon } from 'joy-ui';

import ModalTemplate from '@template/Overlays/ModalTemplate';

import styles from './UploadLocationModal.module.scss';

interface IProps {
  open: boolean;
  albums: Array<JoyAlbum> | null;
  onClose: () => void;
  onSelect: (location: string) => void;
}

class UploadLocationModal extends Component<IProps> {
  handleGroupSelect = () => {
    this.props.onSelect('group');
  };

  handleNewAlbumSelect = () => {
    this.props.onSelect('newAlbum');
  };

  handleAlbumSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.props.onSelect(event.currentTarget.dataset.album || '');
  };

  render() {
    const { open, albums, onClose } = this.props;

    return (
      <ModalTemplate open={open} legend={<Text variant="heading">Add memories to album</Text>} onClose={onClose}>
        <div className={styles.listAction} onClick={this.handleGroupSelect}>
          <div className={styles.actionIcon}>
            <AddIcon color="white" size={32} />
          </div>
          <Text variant="subtitle">Add to group</Text>
        </div>

        <div className={styles.listAction} onClick={this.handleNewAlbumSelect}>
          <div className={styles.actionIcon}>
            <AddIcon color="white" size={32} />
          </div>
          <Text variant="subtitle">Create new album</Text>
        </div>

        <div className={styles.albumsList}>
          {albums?.map((item) => {
            let thumbnail = item?.coverMedium?.thumbnailUri || '';

            return (
              <div key={item.id} className={styles.listAction} data-album={item.id} onClick={this.handleAlbumSelect}>
                <div
                  className={styles.actionIcon}
                  style={{
                    backgroundImage: `url(${thumbnail})`
                  }}
                >
                  {thumbnail ? null : <PhotoIcon color="white" size={32} />}
                </div>

                <Text variant="subtitle">{item.name}</Text>
              </div>
            );
          })}
        </div>
      </ModalTemplate>
    );
  }
}

export default UploadLocationModal;
