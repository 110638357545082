import React, { Component } from 'react';
import { Text, Input, Button } from 'joy-ui';

import ModalTemplate from '@template/Overlays/ModalTemplate';
import { JoyGroup, JoyGroupHelper } from 'joy-co/core/build';
import SnackbarHelper from '@helpers/SnackbarHelper';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (group: JoyGroup) => void;
}

interface IState {
  isBusy: boolean;
  groupName: string;
  groupNameError: string;
}

class AddNewGroup extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isBusy: false,
      groupName: '',
      groupNameError: ''
    };
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState({ [name]: value, [`${name}Error`]: '' } as Pick<IState, 'groupName'>);
  };

  handleClose = () => {
    this.setState({ groupName: '', groupNameError: '' }, this.props.onClose);
  };

  handleAddGroup = () => {
    this.setState({ isBusy: true }, async () => {
      if (this.checkFormErrors()) {
        const newGroup = await JoyGroupHelper.create(this.state.groupName);

        if (newGroup) {
          this.setState({ groupName: '', groupNameError: '' }, () => {
            this.props.onSubmit(newGroup);
          });
        } else {
          SnackbarHelper.showError('Could not create the group! Server communication failed!');
        }

        this.setState({ isBusy: false });
      } else {
        this.setState({ isBusy: false });
      }
    });
  };

  checkFormErrors(): boolean {
    const { groupName } = this.state;
    const newState: any = {};

    if (groupName.length === 0) {
      newState.groupNameError = 'Cannot be blank';
    } else if (groupName.length < 3) {
      newState.groupNameError = 'Enter at least 3 characters';
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
      return false;
    }

    return true;
  }

  render() {
    const {
      state: { isBusy, groupName, groupNameError },
      props: { open }
    } = this;

    return (
      <ModalTemplate
        open={open}
        legend={<Text variant="heading">Create group</Text>}
        onClose={this.handleClose}
        cardActions={
          <Button color="primary" isBusy={isBusy} onClick={this.handleAddGroup}>
            Create
          </Button>
        }
      >
        <Input
          name="groupName"
          label="Group Name"
          placeholder="Add name or subject"
          value={groupName}
          error={groupNameError}
          onChange={this.handleChange}
        />

        <br />
        <Text color="muted" variant="body">
          Give your group a name. It can be anything you like; for your family, friends or with just your best friend.
        </Text>
        <br />
        <br />
      </ModalTemplate>
    );
  }
}

export default AddNewGroup;
