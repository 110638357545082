import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Avatar, Text } from 'joy-ui/components';

import { ROUTES } from '@utils/system';
import { RootState } from '@utils/redux/store';
import { getGroups, setCurrentGroup, getAlbums, setCurrentAlbum, getPeople } from '@utils/redux/media/actions';
import GroupOptions from './GroupOptions';

import styles from './PeopleHeader.module.scss';

const mapStateToProps = ({ user, system, media }: RootState) => ({
  currentUser: user.currentUser,
  currentGroup: media.currentGroup,
  currentAlbum: media.currentAlbum,
  albums: media.albums,
  people: media.people,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = { getGroups, setCurrentGroup, getAlbums, setCurrentAlbum, getPeople };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux, RouteComponentProps {
  loading?: boolean;
  onTitleClick: () => void;
  loadAllGroups: () => void;
}

class PeopleHeader extends Component<IProps> {
  loadGroups = async () => {
    const { currentUser, currentGroup, getGroups, setCurrentGroup } = this.props;

    if (currentUser) {
      const { groups } = await getGroups(currentUser.id);

      if (currentGroup) {
        const newCurrentGroup = groups.find((item) => item.id === currentGroup.id);

        if (newCurrentGroup) {
          setCurrentGroup(newCurrentGroup);
        }
      }
    }
  };

  loadPeople = async () => {
    const { currentGroup, getPeople } = this.props;

    if (currentGroup) {
      getPeople(currentGroup.id);
    }
  };

  handleGroupDelete = () => {
    const { setCurrentGroup, loadAllGroups, history } = this.props;

    setCurrentGroup(null);
    history.push(ROUTES.home);
    loadAllGroups();
  };

  get groupOwner() {
    const { currentGroup, currentUser } = this.props;

    if (currentGroup && currentUser) {
      return currentUser.groups[currentGroup.id] === 'owner';
    }

    return false;
  }

  render() {
    const { loading, currentGroup, currentAlbum, currentUser, people, onTitleClick } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <Text className={styles.groupTitle} variant="headingSmall" onClick={onTitleClick} title={currentGroup?.name}>
            {currentGroup?.name}
          </Text>

          {currentAlbum && (
            <Text className={styles.albumTitle} variant="headingSmall" title={currentAlbum.name}>
              &nbsp;{`> ${currentAlbum.name}`}
            </Text>
          )}
        </div>

        <div className={styles.peopleList}>
          {people?.map((user) => (
            <Avatar key={user.id} name={user.name} photoUrl={user.photoUrl} size={30} className={styles.personAvatar} />
          ))}

          <GroupOptions
            group={currentGroup}
            people={people}
            loadingPeople={loading}
            isOwner={this.groupOwner}
            currentUserId={currentUser?.id || ''}
            onGroupUpdate={this.loadGroups}
            onGroupDelete={this.handleGroupDelete}
            onPeopleUpdate={this.loadPeople}
          />
        </div>
      </div>
    );
  }
}

export default connector(withRouter(PeopleHeader));
