import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { JoyMedium, JoyUserRole } from 'joy-core';
import {
  IconButton,
  Text,
  RadioCheckedIcon,
  RadioUncheckedIcon,
  VideoCameraIcon,
  MoreHorizIcon,
  DropDownMenu,
  DropDownItem,
  Button,
  LoadingLoop
} from 'joy-ui';

import { RootState } from '@utils/redux/store';

import styles from './MediaGrid.module.scss';

const mapStateToProps = ({ system, media, user }: RootState) => ({
  currentUser: user.currentUser,
  currentGroup: media.currentGroup,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux {
  items: Array<JoyMedium>;
  busyItems?: Array<string>;
  actions?: React.ReactNode;
  selectedItems?: Array<string>;
  simpleItem?: boolean;
  showSelected?: boolean;
  publicView?: boolean;
  itemOptions?: (itemId: string) => React.ReactNode;
  onSelect?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  generateItemUrl: (mediaId: string | undefined) => string;
}

interface IState {
  deleteMediaItem: string | null;
}

class MediaGrid extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      deleteMediaItem: null
    };
  }

  enableSelect = (createdBy: string) => {
    const { currentUser, currentGroup } = this.props;

    if (currentUser && currentGroup) {
      return currentUser.id === createdBy || currentUser.groups[currentGroup.id] === JoyUserRole.owner;
    }

    return false;
  };

  getContextMenu = (mediaItem: JoyMedium) => {
    const { currentUser, currentGroup, itemOptions, onDelete } = this.props;
    const groupId = currentGroup?.id || '';
    const options: Array<React.ReactNode> = [];

    if (itemOptions) {
      const computedOptions = itemOptions(mediaItem.id);

      if (computedOptions) {
        options.push(computedOptions);
      }
    }

    if (onDelete && (currentUser?.id === mediaItem.createdBy || currentUser?.groups[groupId] === JoyUserRole.owner)) {
      options.push(
        <DropDownItem key="delete" label="Delete media" data-value={mediaItem.id} onClick={this.setRemovableItem} />
      );
    }

    if (options.length > 0) {
      return (
        <DropDownMenu anchor={<IconButton icon={MoreHorizIcon} className={styles.optionsMenu} color="white" />}>
          {options.map((item) => item)}
        </DropDownMenu>
      );
    }
    return null;
  };

  renderItem = (item: JoyMedium, showSelected: boolean = false, isBusy: boolean = false) => {
    const {
      state: { deleteMediaItem },
      props: { selectedItems, simpleItem, generateItemUrl, onSelect, onDelete, isMobile }
    } = this;
    const enableSelect = this.enableSelect(item.createdBy);
    const isSelected = selectedItems ? selectedItems.includes(item.id) : false;
    const catchClick = showSelected && enableSelect ? { onClick: this.catchLinkClick } : {};
    const { r, g, b, a } = item.meanColor;

    return (
      <div
        key={`media-${item.id}`}
        className={classNames(styles.mediaItem, { [styles.mediaItemMobile]: isMobile, [styles.idleItem]: !isBusy })}
        style={{ flex: `${item.width / item.height} 0%`, backgroundColor: `rgba(${r},${g},${b},${a})` }}
      >
        {isBusy && (
          <div className={styles.loopOverlay}>
            <LoadingLoop color="white" />
          </div>
        )}

        {deleteMediaItem === item.id ? (
          <div className={styles.deleteConfirmation}>
            <Text variant="subtitle" color="white">
              <b>Delete this item ?</b>
            </Text>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button color="error" className={styles.yesOption} data-value={item.id} onClick={onDelete}>
              Yes, delete
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button color="white" variant="text" className={styles.noOption} onClick={this.clearRemovableItem}>
              No, cancel
            </Button>
          </div>
        ) : (
          <>
            {!simpleItem && enableSelect && (
              <IconButton
                className={classNames(styles.itemSelect, { [styles.showSelected]: showSelected })}
                icon={isSelected ? RadioCheckedIcon : RadioUncheckedIcon}
                color="white"
                data-value={item.id}
                onClick={onSelect}
              />
            )}

            <Link
              className={classNames({ [styles.mediaItemSelected]: isSelected })}
              to={generateItemUrl(item.id)}
              data-value={item.id}
              {...catchClick}
            >
              <img src={item.thumbnailUri} alt={item.id} className={styles.thumbnail} draggable="false" />
            </Link>

            {this.getContextMenu(item)}

            {item.mimeType.startsWith('video') && <VideoCameraIcon className={styles.videoMark} color="white" />}
          </>
        )}
      </div>
    );
  };

  setRemovableItem = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const mediaId = event.currentTarget.dataset.value;

    if (mediaId) {
      this.setState({ deleteMediaItem: mediaId });
    }
  };

  clearRemovableItem = () => {
    this.setState({ deleteMediaItem: null });
  };

  catchLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(event);
    }
  };

  render() {
    const { items, actions, publicView, showSelected, busyItems = [], isMobile } = this.props;

    return (
      <>
        {actions}

        {!publicView && items.length === 0 ? (
          <Text className={styles.mediaGridMessage} variant="body" color="muted">
            This photosession is empty. Please add new files.
          </Text>
        ) : isMobile ? (
          splitMediaMobile(items).map((row: Array<JoyMedium>, index: number) => (
            <div key={`mediaRow-${index}`} className={classNames(styles.row, styles.rowMobile)}>
              {row.map((item: JoyMedium) => this.renderItem(item, showSelected, busyItems.includes(item.id)))}
            </div>
          ))
        ) : (
          splitMedia(items).map((row: Array<JoyMedium>, index: number) => (
            <div key={`mediaRow-${index}`} className={styles.row}>
              {row.map((item: JoyMedium) => this.renderItem(item, showSelected, busyItems.includes(item.id)))}
            </div>
          ))
        )}
      </>
    );
  }
}

export default connector(MediaGrid);

/**
 * This algorithm was carried over from the old web app
 *
 * @param items
 */
function splitMedia(items: Array<JoyMedium>) {
  return items.reduce(
    (rows: Array<Array<JoyMedium>>, item: JoyMedium, index: number) => {
      const isLast = index === items.length - 1;
      const landscape = item.width > item.height;
      const { length: l, [l - 1]: last } = rows;

      switch (true) {
        case isLast:
          last.push(item);
          break;
        case last.length > 2:
        case last.length >= 2 && landscape:
          rows.push([item]);
          break;
        default:
          last.push(item);
      }

      return rows;
    },
    [[]]
  );
}

/**
 *
 * @param items
 */
function splitMediaMobile(items: Array<JoyMedium>) {
  const length = items.length;
  const newItems: Array<Array<JoyMedium>> = [];

  for (let i = 0; i < length; i += 2) {
    if (items[i + 1]) {
      newItems.push([items[i], items[i + 1]]);
    } else {
      newItems.push([items[i]]);
    }
  }

  return newItems;
}
