import React, { Component } from 'react';
import { isEmail, JoyLogHelper, JoyTrackHelper } from 'joy-core';
import { EmailInput, Text, NativeLink } from 'joy-ui/components';

import FormPageTemplate from '@template/Pages/FormPageTemplate';
import MessagePageTemplate from '@template/Pages/MessagePageTemplate';
import AuthHelper from '@helpers/AuthHelper';
import SnackbarHelper from '@helpers/SnackbarHelper';
import styles from './ForgotPassword.module.scss';

interface IState {
  email: string;
  emailError: string;
  requestSent: boolean;
  isBusy: boolean;
}
// TODO: handle display only if all params are received
class ForgotPassword extends Component<any, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      emailError: '',
      requestSent: false,
      isBusy: false
    };
  }

  componentDidMount() {
    const { state } = this.props.location;

    if (state) {
      const { email } = state;

      if (isEmail(email)) {
        this.setState({ email: email });
      }
    }

    JoyTrackHelper.sendScreensEvent('forgot_pwd');
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState({ [name]: value, [`${name}Error`]: '' } as Pick<IState, 'email'>);
  };

  handleSubmit = () => {
    this.setState({ isBusy: true }, async () => {
      if (this.checkFormErrors()) {
        const { email } = this.state;

        try {
          const result = await AuthHelper.sendResetPasswordEmail(email);

          if (result) {
            this.setState({ isBusy: false, requestSent: true }, () => {
              JoyTrackHelper.sendScreensEvent('forgot_pwd_email_sent');
            });
          } else {
            SnackbarHelper.showError('Failed to send reset password email.');
            this.setState({ isBusy: false });
          }
        } catch (error) {
          JoyLogHelper.error({ error, email, place: 'ForgotPassword.handleSubmit' });
        }
      } else {
        this.setState({ isBusy: false });
      }
    });
  };

  checkFormErrors(): boolean {
    const { email } = this.state;
    const newState: any = {};

    if (email.length === 0) {
      newState.emailError = 'Cannot be blank';
    } else if (isEmail(email) === false) {
      newState.emailError = 'Email address is not valid';
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
      return false;
    }

    return true;
  }

  get legendAuth() {
    return <Text>We'll send you a link to reset your password.</Text>;
  }

  get legendMessage() {
    return <Text>Email sent.</Text>;
  }

  render() {
    const { email, emailError, requestSent, isBusy } = this.state;

    if (requestSent) {
      return (
        <MessagePageTemplate legend={this.legendMessage}>
          <Text variant="body">
            It might take a minute or two. If you do not receive it within a few minutes please contact us.
          </Text>

          <NativeLink href="mailto:hello@joy.co?subject=Password%20help" className={styles.helpLink}>
            hello@joy.co
          </NativeLink>
        </MessagePageTemplate>
      );
    }

    return (
      <FormPageTemplate
        legend={this.legendAuth}
        submitText="Reset password"
        isBusy={isBusy}
        onSubmit={this.handleSubmit}
      >
        <EmailInput name="email" value={email} error={emailError} onChange={this.handleChange} />
      </FormPageTemplate>
    );
  }
}

export default ForgotPassword;
