import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { JoyStatus, JoyLogHelper } from 'joy-core';
import { Button, IconButton, LoadingLoop, Text } from 'joy-ui/components';
import { CloseIcon, DeleteIcon } from 'joy-ui/icons';

import NavigationViewTemplate from '@template/Pages/NavigationViewTemplate';
import DialogTemplate from '@template/Overlays/DialogTemplate';
import Header from '@template/Header';
import {
  getGroups,
  setCurrentGroup,
  getAlbums,
  setCurrentAlbum,
  getGroupMedia,
  getAlbumMedia,
  getThumbnailMedia,
  deleteMedia,
  getPeople,
  clearSelectedItems
} from '@utils/redux/media/actions';
import { RootState } from '@utils/redux/store';
import { ROUTES } from '@utils/system';
import SnackbarHelper from '@helpers/SnackbarHelper';
import PeopleHeader from './PeopleHeader';
import EmptyGroup from './EmptyGroup';
import NoGroup from './NoGroup';
import AlbumList from './AlbumList';
import MediaContainer from './MediaContainer';
import UploadManager from '../UploadManager';
import styles from './Home.module.scss';

const mapStateToProps = ({ user, system, media, upload }: RootState) => ({
  currentUser: user.currentUser,
  currentGroup: media.currentGroup,
  currentAlbum: media.currentAlbum,
  currentMedia: media.currentMedia,
  thumbnailMedia: media.thumbnailMedia,
  selectedItems: media.selectedItems,
  groups: media.groups,
  loadingGroups: media.loadingGroups,
  loadingAlbums: media.loadingAlbums,
  loadingMedia: media.loadingMedia,
  loadingPeople: media.loadingPeople,
  albums: media.albums,
  people: media.people,
  uploadState: upload,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {
  getGroups,
  setCurrentGroup,
  getAlbums,
  setCurrentAlbum,
  getGroupMedia,
  getAlbumMedia,
  getThumbnailMedia,
  deleteMedia,
  getPeople,
  clearSelectedItems
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MatchParams {
  groupId?: string;
  albumId?: string;
  mediaId?: string;
}

interface IProps extends PropsFromRedux, RouteComponentProps<MatchParams> {}

interface IState {
  openAddAlbum: boolean;
  openUpload: boolean;
  openUploadLocation: boolean;
  openDeleteMedia: boolean;
  uploadGroupId: string;
  uploadAlbumId: string;
  uploadFiles: FileList | null;
}

class Home extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openAddAlbum: false,
      openUpload: false,
      openUploadLocation: false,
      openDeleteMedia: false,
      uploadGroupId: '',
      uploadAlbumId: '',
      uploadFiles: null
    };
  }

  componentDidMount() {
    this.loadGroups();
  }

  componentDidUpdate(prevProps: IProps) {
    const { currentGroup: prevGroup } = prevProps;
    const {
      currentGroup,
      match: {
        params: { albumId }
      }
    } = this.props;

    if (currentGroup && currentGroup.id !== prevGroup?.id) {
      this.loadAlbums();
      this.loadPeople();

      if (albumId) {
        this.loadAlbumMedia(currentGroup?.id, albumId);
      } else {
        this.loadGroupMedia(currentGroup?.id);
      }

      this.loadGroupThumbnail(currentGroup?.id);
    }
  }

  handleMediaReload = () => {
    const {
      currentGroup,
      match: {
        params: { albumId }
      }
    } = this.props;

    if (albumId) {
      this.loadAlbumMedia(currentGroup?.id, albumId);
    } else {
      this.loadGroupMedia(currentGroup?.id);
    }

    this.loadGroupThumbnail(currentGroup?.id || '');
  };

  loadGroups = async () => {
    const {
      currentUser,
      getGroups,
      setCurrentGroup,
      match: {
        params: { groupId, albumId, mediaId }
      }
    } = this.props;

    if (currentUser) {
      const { groups } = await getGroups(currentUser.id);
      let newGroup = groups[0];

      if (groups.length) {
        if (groupId) {
          const currentGroup = groups.find((item) => item.id === groupId);

          if (currentGroup) {
            newGroup = currentGroup;
          } else {
            this.props.history.push(this.generateGroupUrl(groups[0].id));

            SnackbarHelper.showError('The group you were trying to access is not available!');
          }
        } else {
          if (albumId || mediaId) {
            this.props.history.push(this.generateGroupUrl(groups[0].id));
          }
        }

        setCurrentGroup(newGroup);

        this.loadGroupThumbnail(newGroup.id);

        this.loadAlbums();
        this.loadPeople();

        if (albumId) {
          this.loadAlbumMedia(this.props.currentGroup?.id, albumId);
        } else {
          this.loadGroupMedia(this.props.currentGroup?.id);
        }
      } else {
        setCurrentGroup(null);

        this.props.history.push(ROUTES.home);
      }
    }
  };

  loadAlbums = async () => {
    const {
      currentGroup,
      getAlbums,
      setCurrentAlbum,
      match: {
        params: { albumId }
      }
    } = this.props;

    if (currentGroup) {
      const { albums } = await getAlbums(currentGroup.id);

      if (albumId) {
        const currentAlbum = albums.find((item) => item.id === albumId);

        if (currentAlbum) {
          setCurrentAlbum(currentAlbum);
        } else {
          this.props.history.push(this.generateGroupUrl(currentGroup.id));

          SnackbarHelper.showError('The album you were trying to access is not available!');
        }
      }
    }
  };

  async loadGroupMedia(groupId: string | undefined) {
    const {
      getGroupMedia,
      match: {
        params: { mediaId }
      }
    } = this.props;

    if (groupId) {
      const { currentMedia: media } = await getGroupMedia(groupId, 0, [JoyStatus.processed]);

      if (mediaId) {
        const currentMedia = media.find((item) => item.id === mediaId);

        if (!currentMedia) {
          this.props.history.push(this.generateGroupUrl(groupId));

          SnackbarHelper.showError('The media you were trying to access is not available!');
        }
      }
    }
  }

  loadGroupThumbnail = (groupId: string) => {
    this.props.getThumbnailMedia(groupId);
  };

  async loadAlbumMedia(groupId: string | undefined, albumId: string | undefined) {
    const {
      getAlbumMedia,
      match: {
        params: { mediaId }
      }
    } = this.props;

    if (groupId && albumId) {
      const { currentMedia: media } = await getAlbumMedia(albumId, groupId, 0, [JoyStatus.processed]);

      if (mediaId) {
        const currentMedia = media.find((item) => item.id === mediaId);

        if (!currentMedia) {
          this.props.history.push(this.generateAlbumUrl(groupId, albumId));

          SnackbarHelper.showError('The media you were trying to access is not available!');
        }
      }
    }
  }

  loadPeople() {
    const { currentUser, currentGroup, getPeople } = this.props;

    if (currentUser && currentGroup) {
      getPeople(currentGroup.id);
    }
  }

  handleAlbumSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { currentGroup, albums, setCurrentAlbum } = this.props;

    const currentAlbum = albums?.find((item) => item.id === event.currentTarget.dataset.album);

    if (currentGroup && currentAlbum) {
      setCurrentAlbum(currentAlbum);

      this.loadAlbumMedia(currentGroup.id, currentAlbum?.id);

      this.props.history.push(this.generateAlbumUrl(currentGroup.id, currentAlbum.id));
    }
  };

  handleAlbumDelete = () => {
    this.reloadGroupMedia();
  };

  handleDeleteMedia = async (response: boolean) => {
    if (response) {
      const { currentGroup, selectedItems, deleteMedia } = this.props;

      if (currentGroup) {
        try {
          await deleteMedia(currentGroup.id, selectedItems);

          this.loadGroupThumbnail(currentGroup?.id);
        } catch (error) {
          JoyLogHelper.error({ error, groupId: currentGroup.id, selectedItems, place: 'Home.handleDeleteMedia' });
        }
      }
    }
  };

  handleDeleteSingleMedia = async (mediaId: string) => {
    const { currentGroup, deleteMedia } = this.props;

    if (currentGroup) {
      try {
        await deleteMedia(currentGroup.id, [mediaId]);

        this.loadGroupThumbnail(currentGroup?.id);
      } catch (error) {
        JoyLogHelper.error({ error, groupId: currentGroup.id, mediaId, place: 'Home.handleDeleteSingleMedia' });
      }
    }
  };

  reloadGroupMedia = () => {
    const { currentGroup, history, setCurrentAlbum } = this.props;

    setCurrentAlbum(null);

    this.loadGroupMedia(currentGroup?.id);

    history.push(this.generateGroupUrl(currentGroup?.id));
  };

  openUploadManager = () => {
    this.setState({ openUpload: true });
  };

  closeUploadManager = () => {
    this.setState({ openUpload: false });
  };

  openDeleteMedia = () => {
    this.setState({ openDeleteMedia: true });
  };

  closeDeleteMedia = () => {
    this.setState({ openDeleteMedia: false });
  };

  generateGroupUrl = (groupId: string | undefined): string => {
    if (groupId) {
      return ROUTES.homeGroup.replace(':groupId', groupId);
    }

    return '';
  };

  generateAlbumUrl = (groupId: string | undefined, albumId: string | undefined): string => {
    if (groupId) {
      if (albumId) {
        return ROUTES.homeGroupAlbum.replace(':groupId', groupId).replace(':albumId', albumId);
      }

      return ROUTES.homeGroup.replace(':groupId', groupId);
    }

    return '';
  };

  get albumHeader() {
    const { selectedItems } = this.props;

    if (selectedItems.length > 0) {
      return (
        <Header
          hideLogo
          leftContent={
            <div className={styles.headerLeftContent}>
              <IconButton icon={CloseIcon} color="white" onClick={this.props.clearSelectedItems} />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Text variant="body" color="white">
                {selectedItems.length} selected
              </Text>
            </div>
          }
          // middleContent={
          //   <Text variant="body" color="white">
          //     Press <b>Esc</b> to deselect all
          //   </Text>
          // }
          content={<IconButton icon={DeleteIcon} color="white" onClick={this.openDeleteMedia} />}
          className={styles.headerWrapper}
          fixedHeader
        />
      );
    }

    return null;
  }

  render() {
    const {
      state: { openUpload, openDeleteMedia },
      props: {
        currentGroup,
        currentAlbum,
        currentMedia,
        currentUser,
        thumbnailMedia,
        groups,
        albums,
        loadingGroups,
        loadingAlbums,
        loadingPeople,
        loadingMedia,
        match: {
          params: { mediaId }
        }
      }
    } = this;

    let displayCheck = !!(albums?.length || currentMedia.length);
    let groupsCheck = groups?.length === 0;

    return (
      <NavigationViewTemplate
        header={this.albumHeader}
        headerContent={
          <>
            {displayCheck && (
              <Button color="primary" size="large" onClick={this.openUploadManager}>
                Add memories
              </Button>
            )}
          </>
        }
        fixedHeader
      >
        {loadingGroups || (loadingAlbums && !groupsCheck) ? (
          <LoadingLoop color="muted" className={styles.loadingLoop} />
        ) : groupsCheck ? (
          <NoGroup />
        ) : (
          <>
            <PeopleHeader
              loading={loadingPeople}
              onTitleClick={this.reloadGroupMedia}
              loadAllGroups={this.loadGroups}
            />

            {displayCheck ? (
              <>
                <div className={styles.wrapper}>
                  <AlbumList
                    albums={albums}
                    currentAlbum={currentAlbum}
                    currentGroup={currentGroup}
                    currentUser={currentUser}
                    thumbnailMedia={thumbnailMedia}
                    onSelect={this.handleAlbumSelect}
                    onDelete={this.handleAlbumDelete}
                    onAllMediaSelect={this.reloadGroupMedia}
                  />

                  <MediaContainer
                    loading={loadingMedia}
                    selectedId={mediaId}
                    modalCloseUrl={this.generateAlbumUrl(currentGroup?.id, currentAlbum?.id)}
                    onDeleteMedia={this.handleDeleteSingleMedia}
                    onSetCover={this.loadAlbums}
                  />
                </div>
              </>
            ) : (
              <EmptyGroup onAddMedia={this.openUploadManager} />
            )}
          </>
        )}

        <DialogTemplate
          open={openDeleteMedia}
          yesLabel="Yes, delete items"
          noLabel="Cancel, do not delete"
          onClose={this.closeDeleteMedia}
          onResponse={this.handleDeleteMedia}
        >
          <Text variant="heading" className={styles.dialogTitle}>
            Delete selected items ?
          </Text>
          <br />
          <Text variant="subtitle" className={styles.dialogSubtitle}>
            All deleted items will be lost forever. Please make sure you have backups.
          </Text>
        </DialogTemplate>

        <UploadManager
          open={openUpload}
          onClose={this.closeUploadManager}
          loadAlbums={this.loadAlbums}
          onUploadEnd={this.handleMediaReload}
        />
      </NavigationViewTemplate>
    );
  }
}

export default connector(withRouter(Home));
