import store from '@utils/redux/store';
import {
  getPeople,
  setGroups,
  setCurrentGroup,
  setAlbums,
  setCurrentAlbum,
  setCurrentMedia,
  getThumbnailMedia,
  getPeopleAvatars,
  getGroupsOwners
} from '@utils/redux/media/actions';
import { JoyAlbumsObserver, JoyField, JoyGroup, JoyMediumsObserver, JoyObjectObserver, JoyStatus } from 'joy-core';

class ObserverHelper {
  static state() {
    return store.getState();
  }

  static addGroupObserver(group: JoyGroup | null) {
    if (group) {
      const groupId = group.id;
      const groupObserver = new JoyObjectObserver((updates) => {
        const { groups, currentGroup } = ObserverHelper.state().media;

        if (groups && currentGroup) {
          if (currentGroup.id === groupId) {
            updates.forEach((update) => {
              switch (update) {
                case JoyField.users:
                  store.dispatch(getPeople(currentGroup.id));
                  store.dispatch(getPeopleAvatars([currentGroup]));
                  break;
                case JoyField.name:
                case JoyField.description:
                  const { id, createdAt, createdBy, name, description, users } = currentGroup;
                  const newGroup = new JoyGroup({ id, createdAt, createdBy, name, description, users });

                  store.dispatch(setCurrentGroup(newGroup));
                  store.dispatch(setCurrentGroup(currentGroup));
                  break;
                default:
                  break;
              }
            });
          } else {
            const thisGroup = groups.find((group) => group.id === groupId);

            if (thisGroup) {
              updates.forEach((update) => {
                switch (update) {
                  case JoyField.users:
                    store.dispatch(getPeopleAvatars([thisGroup]));
                    break;
                  case JoyField.name:
                  case JoyField.description:
                    const newGroups = [...groups];

                    store.dispatch(setGroups(newGroups));
                    break;
                  default:
                    break;
                }
              });
            }
          }
        }
      });

      store.dispatch(getPeopleAvatars([group]));

      group.addObserver(groupObserver);
    }
  }

  static async getGroupOwners(groups: Array<JoyGroup>, userId: string) {
    return store.dispatch(await getGroupsOwners(groups, userId));
  }

  static createAlbumsObserver(groupId: string): JoyAlbumsObserver {
    const albumsObserver = new JoyAlbumsObserver(groupId, ([albums, changes]) => {
      const { currentAlbum } = ObserverHelper.state().media;

      store.dispatch(setAlbums(albums));

      if (currentAlbum) {
        const newAlbum = albums.find((album) => album.id === currentAlbum.id);

        if (newAlbum) {
          store.dispatch(setCurrentAlbum(newAlbum));
        } else {
          store.dispatch(setCurrentAlbum(null));
        }
      }
    });

    return albumsObserver;
  }

  static createMediumsObserver(groupId: string = '', albumId?: string): JoyMediumsObserver {
    const { currentGroup } = ObserverHelper.state().media;

    if (!groupId && currentGroup) {
      groupId = currentGroup.id;
    }

    const mediumsObserver = new JoyMediumsObserver(
      groupId,
      ([mediums, changes]) => {
        store.dispatch(setCurrentMedia(mediums));
        store.dispatch(getThumbnailMedia(groupId));
      },
      albumId,
      [JoyStatus.processed]
    );

    return mediumsObserver;
  }

  static disposeObservers(observers: Array<'albums' | 'mediums'>) {
    const { albumsObserver, mediumsObserver } = ObserverHelper.state().media;

    observers.forEach((observer) => {
      switch (observer) {
        case 'albums':
          if (albumsObserver) {
            albumsObserver.dispose();
          }
          break;
        case 'mediums':
          if (mediumsObserver) {
            mediumsObserver.dispose();
          }
          break;
      }
    });
  }
}

export default ObserverHelper;
