import React, { Component } from 'react';
import { JoyGroup, JoyGroupHelper, JoyUser, JoyLogHelper } from 'joy-core';
import {
  DropDownCard,
  Avatar,
  IconButton,
  Text,
  Input,
  LoadingLoop,
  InlineConfirmation,
  Button
} from 'joy-ui/components';
import { AddIcon, CloseIcon, DoneIcon, EditIcon, SettingsIcon } from 'joy-ui/icons';

import SnackbarHelper from '@helpers/SnackbarHelper';
import DialogTemplate from '@template/Overlays/DialogTemplate';
import AddPeopleModal from '../AddPeopleModal';

import styles from './GroupOptions.module.scss';

interface IProps {
  group: JoyGroup | null;
  people: JoyUser[] | null;
  currentUserId: string;
  loadingPeople?: boolean;
  isOwner: boolean;
  onGroupUpdate?: () => Promise<void>;
  onGroupDelete?: () => void;
  onPeopleUpdate?: () => Promise<void>;
}

interface IState {
  editGroupName: boolean;
  openAddPeople: boolean;
  openDeleteGroup: boolean;
  removingGroup: boolean;
  updatingGroup: boolean;
  removingUsers: Array<string>;
  groupName: string;
  groupNameError: string;
}

class GroupOptions extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      editGroupName: false,
      openAddPeople: false,
      openDeleteGroup: false,
      removingGroup: false,
      updatingGroup: false,
      removingUsers: [],
      groupName: props.group?.name || '',
      groupNameError: ''
    };
  }

  handleNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ groupName: event.currentTarget.value, groupNameError: '' });
  };

  openNameEditor = () => {
    this.setState({ editGroupName: true }, () => {
      document.getElementById('groupName')?.focus();
    });
  };

  handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.updateGroupName();
    }
  };

  updateGroupName = async () => {
    const { groupName } = this.state;
    const { group, onGroupUpdate } = this.props;

    if (groupName.length === 0) {
      this.setState({ groupNameError: 'Cannot be blank' });
    } else if (groupName.length < 3) {
      this.setState({ groupNameError: 'Enter at least 3 characters' });
    } else if (groupName === group?.name) {
      this.setState({ editGroupName: false });
    } else {
      this.setState({ updatingGroup: true }, async () => {
        try {
          await group?.setName(groupName);

          this.setState({ editGroupName: false });

          if (onGroupUpdate) {
            await onGroupUpdate();
          }
        } catch (error) {
          JoyLogHelper.error({ error, groupId: group?.id, groupName, place: 'GroupOptions.updateGroupName' });

          SnackbarHelper.showError('Could not update group.');
        } finally {
          this.setState({ updatingGroup: false });
        }
      });
    }
  };

  removeUser = (userId: string) => {
    const { group, onPeopleUpdate } = this.props;

    this.setState(
      (prevState) => ({ removingUsers: [...prevState.removingUsers, userId] }),
      async () => {
        try {
          const result = await JoyGroupHelper.removeUsers(group?.id || '', [userId]);

          if (result.length > 0) {
            throw Error(JSON.stringify(result[0]));
          }

          if (onPeopleUpdate) {
            await onPeopleUpdate();
          }
        } catch (error) {
          JoyLogHelper.error({ error, groupId: group?.id, userId, place: 'GroupOptions.removeUser' });

          SnackbarHelper.showError('Could not remove user.');
        } finally {
          this.setState((prevState: IState) => ({
            removingUsers: prevState.removingUsers.filter((id) => id !== userId)
          }));
        }
      }
    );
  };

  openAddPeople = () => {
    this.setState({ openAddPeople: true });
  };

  closeAddPeople = () => {
    const { onPeopleUpdate } = this.props;

    this.setState({ openAddPeople: false }, () => {
      if (onPeopleUpdate) {
        onPeopleUpdate();
      }
    });
  };

  handleDeleteGroup = (response: boolean) => {
    const { group, onGroupDelete } = this.props;

    if (response && group) {
      this.setState({ removingGroup: true }, async () => {
        try {
          await JoyGroupHelper.delete(group.id);

          if (onGroupDelete) {
            onGroupDelete();
            this.setState({ removingGroup: false });
          }
        } catch (error) {
          JoyLogHelper.error({ error, groupId: group.id, place: 'GroupOptions.handleDeleteGroup' });
          SnackbarHelper.showError('Could not remove group.');
        }
      });
    }
  };

  openDeleteGroup = () => {
    this.setState({ openDeleteGroup: true });
  };

  closeDeleteGroup = () => {
    this.setState({ openDeleteGroup: false });
  };

  render() {
    const {
      state: {
        editGroupName,
        openAddPeople,
        openDeleteGroup,
        removingGroup,
        updatingGroup,
        removingUsers,
        groupName,
        groupNameError
      },
      props: { group, people, loadingPeople, isOwner, currentUserId }
    } = this;
    const groupId = group ? group.id : '';

    return (
      <DropDownCard anchor={<SettingsIcon className={styles.settingsIcon} />}>
        <div className={styles.wrapper}>
          {editGroupName ? (
            <div className={styles.groupNameEditor}>
              <Input
                name="name"
                defaultValue={groupName}
                error={groupNameError}
                className={styles.groupNameInput}
                onChange={this.handleNameChange}
                inputProps={{
                  id: 'groupName',
                  onKeyDownCapture: this.handleKeyDown
                }}
              />

              <IconButton icon={DoneIcon} size={34} onClick={this.updateGroupName} />

              {updatingGroup && (
                <div className={styles.loadingLoop}>
                  <LoadingLoop color="muted" />
                </div>
              )}
            </div>
          ) : (
            <div className={styles.groupNameDisplay}>
              <Text className={styles.groupNameLabel} title={group?.name}>
                {group?.name}
              </Text>

              {isOwner && <IconButton icon={EditIcon} size={36} onClick={this.openNameEditor} />}
            </div>
          )}

          <br />
          <br />
          <Text variant="bodySmall" color="muted" className={styles.listTitle}>
            GROUP MEMBERS
          </Text>
          <br />

          {loadingPeople && !people ? (
            <>
              <br />
              <br />
              <br />
              <LoadingLoop color="muted" />
              <br />
              <br />
              <br />
            </>
          ) : (
            <div className={styles.peopleList}>
              {isOwner && (
                <div className={styles.listAction}>
                  <div className={styles.actionIcon} onClick={this.openAddPeople}>
                    <AddIcon color="white" size={30} />
                  </div>

                  <Text variant="subtitle">Add a Member</Text>
                </div>
              )}

              {people?.map((user) => {
                return (
                  <div key={user.id} className={styles.listAction}>
                    <Avatar name={user.name} photoUrl={user.photoUrl} size={40} className={styles.userAvatar} />

                    <Text variant="subtitle" className={styles.userName} title={user.primaryEmail}>
                      {user.name}
                    </Text>

                    {user.groups[groupId] === 'owner' ? (
                      <Text variant="paragraph" color="muted">
                        Owner
                      </Text>
                    ) : (
                      (isOwner || user.id === currentUserId) &&
                      (removingUsers.includes(user.id) ? (
                        <div className={styles.loadingLoop}>
                          <LoadingLoop color="muted" />
                        </div>
                      ) : (
                        <InlineConfirmation
                          anchor={<IconButton icon={CloseIcon} color="muted" />}
                          label="Remove member ?"
                          payload={user.id}
                          onConfirm={this.removeUser}
                        />
                      ))
                    )}
                  </div>
                );
              })}
            </div>
          )}

          <br />
          <br />
          <Text variant="body" color="muted">
            Subscribers can add media, download media, copy media, and remove their own media from this album.
          </Text>

          {isOwner && (
            <>
              <br />
              <Button
                variant="text"
                color="error"
                disabled={removingGroup}
                isBusy={removingGroup}
                className={styles.groupDeleteButton}
                onClick={this.openDeleteGroup}
              >
                Delete Group
              </Button>
            </>
          )}
        </div>

        <AddPeopleModal open={openAddPeople} group={group} people={people} onClose={this.closeAddPeople} />

        <DialogTemplate
          open={openDeleteGroup}
          yesLabel="Yes, delete group"
          noLabel="Cancel, do not delete"
          onClose={this.closeDeleteGroup}
          onResponse={this.handleDeleteGroup}
        >
          <Text variant="heading" className={styles.dialogTitle}>
            Delete this group?
          </Text>

          <Text variant="subtitle" className={styles.dialogSubtitle}>
            Deleting this group will remove <b>{group?.name}</b> for you and all the subscribers. Content in this group
            and associated albums will be gone and can't be recovered.
          </Text>
        </DialogTemplate>
      </DropDownCard>
    );
  }
}

export default GroupOptions;
