import store from '@utils/redux/store';
import { resetClient } from '@utils/redux/client/actions';
import { resetMedia } from '@utils/redux/media/actions';
import { resetUpload } from '@utils/redux/upload/actions';
import { setCurrentUser, resetSession } from '@utils/redux/user/actions';
import {
  JoyAnalyticsService,
  JoyAuthService,
  JoyAuthServiceAuthProvider,
  JoyLoggingService,
  JoyProperties,
  JoyProperty,
  JoyUser,
  JoyUserHelper,
  JoyLogHelper
} from 'joy-core';

interface UserCreateProps {
  name: string;
  primaryEmail: string;
  password: string;
  photoUrl?: string;
}

class AuthHelper {
  static async checkUserExists(email: string): Promise<{
    exists: boolean;
    isAnonymous: boolean | null;
    hasPassword: boolean | null;
    properties: JoyProperties;
  } | null> {
    try {
      const result = await JoyUserHelper.findByEmail(email.toLowerCase());

      return result;
    } catch (error) {
      JoyLogHelper.error({ error, email, place: 'AuthHelper.checkUserExists' });

      return null;
    }
  }

  static async createUser({ name, primaryEmail, password }: UserCreateProps): Promise<JoyUser | null> {
    try {
      const result = await JoyUserHelper.create(primaryEmail, password, name);

      return result;
    } catch (error) {
      JoyLogHelper.error({ error, name, primaryEmail, place: 'AuthHelper.createUser' });

      return null;
    }
  }

  static async updateUserState(userId: string, callBack?: (user: JoyUser) => void) {
    const user = await JoyUserHelper.get(userId);

    store.dispatch(setCurrentUser(user));

    JoyAnalyticsService.setUserId(userId);
    JoyLoggingService.setUserId(userId);

    if (callBack) {
      callBack(user);
    }
  }

  static async setAuthUserData(userId: string, callBack?: (user: JoyUser) => void): Promise<void> {
    const state = store.getState();
    const { currentUser } = state.user;

    if (currentUser === null && this.isAuthenticated) {
      await this.updateUserState(userId, callBack);
    } else if (!this.isAuthenticated) {
      store.dispatch(setCurrentUser(null));
    }
  }

  static async sendResetPasswordEmail(email: string): Promise<boolean> {
    try {
      return await JoyAuthService.instance.sendResetPasswordEmail(email.toLowerCase());
    } catch (error) {
      JoyLogHelper.error({ error, place: 'AuthHelper.sendResetPasswordEmail' });

      return false;
    }
  }

  static async resetPassword(token: string, password: string): Promise<boolean> {
    try {
      return await JoyAuthService.instance.resetPassword(token, password);
    } catch (error) {
      JoyLogHelper.error({ error, token, place: 'AuthHelper.resetPassword' });

      return false;
    }
  }

  static getNonce(): Promise<string> {
    return JoyAuthService.instance.getAuthNonce();
  }

  static async signIn(email: string, password: string): Promise<boolean> {
    try {
      const token = await JoyAuthService.instance.emailAuth(email.toLowerCase(), password);
      const userId = await JoyAuthService.instance.createSession(token);

      await this.updateUserState(userId);

      return true;
    } catch (error) {
      JoyLogHelper.error({ error, email, place: 'AuthHelper.signIn' });

      await this.signOut();

      return false;
    }
  }

  static async tokenSignIn(
    tokenId: string,
    provider: JoyAuthServiceAuthProvider
  ): Promise<{ isNewUser: boolean | null; error: boolean }> {
    try {
      const { token, isNewUser } = await JoyAuthService.instance.tokenAuth(tokenId, provider);
      const userId = await JoyAuthService.instance.createSession(token);

      await this.updateUserState(userId);

      return { isNewUser, error: false };
    } catch (error) {
      JoyLogHelper.error({ error, tokenId, provider, place: 'AuthHelper.tokenSignIn' });

      return { isNewUser: null, error };
    }
  }

  static async signOut(): Promise<boolean> {
    store.dispatch(resetClient());
    store.dispatch(resetMedia());
    store.dispatch(resetUpload());
    store.dispatch(resetSession());

    JoyAnalyticsService.reset();
    JoyLoggingService.reset();

    return JoyAuthService.instance.deleteSession();
  }

  static get isAuthenticated(): boolean {
    return Boolean(JoyAuthService.instance.isAuthenticated());
  }

  static userHasRole(role: JoyProperty): boolean {
    const state = store.getState();
    const { currentUser } = state.user;

    if (currentUser) {
      return currentUser.properties.includes(role);
    }

    return false;
  }
}

export default AuthHelper;
