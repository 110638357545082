import store from '@utils/redux/store';
import { setSnackbar } from '@utils/redux/system/actions';

export default class SnackbarHelper {
  static showSnackbar(label: React.ReactNode, delay?: number, autoClose?: boolean) {
    if (label) {
      store.dispatch(
        setSnackbar({
          open: true,
          label,
          delay,
          autoClose,
          variant: 'default'
        })
      );
    }
  }

  static showInfo(label: React.ReactNode, delay?: number, autoClose?: boolean) {
    if (label) {
      store.dispatch(
        setSnackbar({
          open: true,
          label,
          delay,
          autoClose,
          variant: 'info'
        })
      );
    }
  }

  static showWarning(label: React.ReactNode, delay?: number, autoClose?: boolean) {
    if (label) {
      store.dispatch(
        setSnackbar({
          open: true,
          label,
          delay,
          autoClose,
          variant: 'warning'
        })
      );
    }
  }

  static showSuccess(label: React.ReactNode, delay?: number, autoClose?: boolean) {
    if (label) {
      store.dispatch(
        setSnackbar({
          open: true,
          label,
          delay,
          autoClose,
          variant: 'success'
        })
      );
    }
  }

  static showError(label: React.ReactNode, delay?: number, autoClose?: boolean) {
    if (label) {
      store.dispatch(
        setSnackbar({
          open: true,
          label,
          delay,
          autoClose,
          variant: 'error'
        })
      );
    }
  }

  static hideSnackbar() {
    store.dispatch(setSnackbar(null));
  }
}
