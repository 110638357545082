import React, { Component } from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { AppleLogin } from 'react-sign-in-apple';
import {
  isEmail,
  JoyAnalyticsServiceEventAction,
  JoyAuthServiceAuthProvider,
  JoyUserHelper,
  JoyLogHelper,
  JoyTrackHelper
} from 'joy-core';
import { EmailInput, PasswordInput, Text } from 'joy-ui/components';
import googleSignIn from 'joy-ui/assets/img/google-signIn.png';
import appleSignIn from 'joy-ui/assets/img/apple-signIn.png';
import appleSignInDisabled from 'joy-ui/assets/img/apple-signIn-disabled.png';

import FormPageTemplate from '@template/Pages/FormPageTemplate';
import AuthHelper from '@helpers/AuthHelper';
import SnackbarHelper from '@helpers/SnackbarHelper';
import { RootState } from '@utils/redux/store';
import { ROUTES } from '@utils/system';

import styles from './SignIn.module.scss';

const mapStateToProps = ({ system, user }: RootState) => ({
  snackbar: system.snackbar,
  currentUser: user.currentUser
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IState {
  showPassword: boolean;
  email: string;
  emailError: string;
  password: string;
  passwordError: string;
  isBusy: boolean;
  redirectToSignUp: boolean;
  redirectAtLogin: string | null;
  lockEmail: boolean;
  nonce: string;
}

interface IProps extends PropsFromRedux, RouteComponentProps {}

class SignIn extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showPassword: false,
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      isBusy: false,
      redirectToSignUp: false,
      redirectAtLogin: null,
      lockEmail: false,
      nonce: ''
    };
  }

  componentDidMount() {
    JoyTrackHelper.sendScreensEvent('signup_signin');

    const { state }: any = this.props.location;

    if (state && state.redirectAtLogin) {
      this.setState({ redirectAtLogin: state.redirectAtLogin });
    }

    AuthHelper.getNonce().then((response) => {
      this.setState({ nonce: response });
    });
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    if (name === 'email' && this.props.snackbar !== null) {
      SnackbarHelper.hideSnackbar();
    }

    this.setState({ [name]: value, [`${name}Error`]: '' } as Pick<IState, 'email' | 'password'>);
  };

  handleSubmit = () => {
    this.setState({ isBusy: true }, async () => {
      if (this.checkFormErrors()) {
        const { email, password, redirectAtLogin } = this.state;

        if (email && password) {
          try {
            const result = await AuthHelper.signIn(email, password);

            if (result) {
              this.props.history.push(redirectAtLogin ? redirectAtLogin : ROUTES.home);

              JoyTrackHelper.sendUsersEvent('joy', JoyAnalyticsServiceEventAction.login);
            } else {
              // TODO: handle login errors
              this.setState({ isBusy: false, passwordError: 'Incorrect password' });
            }
          } catch (error) {
            JoyLogHelper.error({ error, email, place: 'SignIn.handleSubmit' });
          }
        } else {
          try {
            const result = await AuthHelper.checkUserExists(email);

            if (result) {
              const { exists, isAnonymous, hasPassword } = result;

              if (exists) {
                if (isAnonymous) {
                  this.setState({ redirectToSignUp: true, lockEmail: true });
                } else if (hasPassword) {
                  this.setState({ isBusy: false, showPassword: true }, () => {
                    JoyTrackHelper.sendScreensEvent('login');
                  });
                } else {
                  SnackbarHelper.showError(
                    'Use the third party sign in feature to access your account, then setup a password in your account management.'
                  );

                  this.setState({ isBusy: false });
                }
              } else {
                this.setState({ redirectToSignUp: true, lockEmail: true });
              }
            } else {
              throw Error('Invalid result!');
            }
          } catch (error) {
            JoyLogHelper.error({ error, email, place: 'SignIn.handleSubmit' });

            SnackbarHelper.showError('Connection error!');
          }
        }
      } else {
        SnackbarHelper.showError('Server communication failed!');

        this.setState({ isBusy: false });
      }
    });
  };

  checkFormErrors(): boolean {
    const { showPassword, email, password } = this.state;
    const newState: any = {};

    if (email.length === 0) {
      newState.emailError = 'Cannot be blank';
    } else if (isEmail(email) === false) {
      newState.emailError = 'Email address is not valid';
    }

    if (showPassword && password.length === 0) {
      newState.passwordError = 'Cannot be blank';
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);

      return false;
    }

    return true;
  }

  handleGoogleSignInSuccess = async (result: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    try {
      if ('tokenId' in result) {
        const { tokenId } = result;

        await this.handleOAuthSignIn(tokenId, JoyAuthServiceAuthProvider.google);
      } else {
        throw Error('Could not access Google sign in!');
      }
    } catch (error) {
      let payload: any = { error, place: 'SignIn.handleGoogleSignInSuccess' };

      if ('profileObj' in result) {
        payload.data = result.profileObj;
      }

      JoyLogHelper.error(payload);
    }
  };

  handleGoogleSignInFailure = (error: any) => {
    if (error?.error !== 'popup_closed_by_user') {
      JoyLogHelper.error({ error, place: 'SignIn.handleGoogleSignInFailure' });
      SnackbarHelper.showError('Failed to login user using Google!');
    } else {
      JoyTrackHelper.sendAuthEvent('google', JoyAnalyticsServiceEventAction.cancel);
    }
  };

  handleAppleSignInSuccess = async ({ authorization }) => {
    const { /*code,*/ id_token } = authorization;

    try {
      if (id_token) {
        await this.handleOAuthSignIn(id_token, JoyAuthServiceAuthProvider.apple);
      } else {
        throw Error('Could not access Apple sign in!');
      }
    } catch (error) {
      JoyLogHelper.error({ error, id_token, place: 'SignIn.handleAppleSignInSuccess' });
      SnackbarHelper.showError('Failed to login user using Apple!');
    }
  };

  handleAppleSignInFailure = (error: any) => {
    if (error?.error !== 'popup_closed_by_user') {
      JoyLogHelper.error({ error, place: 'SignIn.handleAppleSignInFailure' });
      SnackbarHelper.showError('Failed to login user using Apple!');
    } else {
      JoyTrackHelper.sendAuthEvent('apple', JoyAnalyticsServiceEventAction.cancel);
    }
  };

  async handleOAuthSignIn(tokenId: string, provider: JoyAuthServiceAuthProvider) {
    const { isNewUser, error } = await AuthHelper.tokenSignIn(tokenId, provider);

    if (error) {
      throw error;
    }

    const { currentUser } = this.props;
    const { redirectAtLogin } = this.state;

    if (currentUser) {
      if (isNewUser) {
        const userGroups = await JoyUserHelper.getGroups(currentUser.id, null, null, 1);

        if (userGroups.length > 0) {
          this.props.history.push(redirectAtLogin ? redirectAtLogin : ROUTES.home);
        } else {
          this.props.history.push({ pathname: ROUTES.onboarding, state: { from: 'signIn' } });
        }
      } else {
        this.props.history.push(redirectAtLogin ? redirectAtLogin : ROUTES.home);
      }
    }
  }

  get legend() {
    return <Text>{this.state.showPassword ? 'Welcome back!' : 'Sign in or create an account.'}</Text>;
  }

  get cardActionsExtension() {
    if (this.state.showPassword) {
      return (
        <Link to={{ pathname: ROUTES.forgotPassword, state: { email: this.state.email } }}>
          <Text variant="body" color="muted">
            Forgot your password?
          </Text>
        </Link>
      );
    }

    return null;
  }

  get pageExtension() {
    const { nonce } = this.state;

    return (
      <div className={styles.pageExtension}>
        <Text variant="body">or continue with</Text>

        <div className={styles.oauthActions}>
          <div
            onClick={() => {
              JoyTrackHelper.sendAuthEvent('google', JoyAnalyticsServiceEventAction.start);
            }}
          >
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID || ''}
              onSuccess={this.handleGoogleSignInSuccess}
              onFailure={this.handleGoogleSignInFailure}
              render={(renderProps) => {
                return (
                  <img
                    className={styles.oauthButton}
                    src={googleSignIn}
                    alt="Sign in with Google"
                    onClick={renderProps.onClick}
                  />
                );
              }}
            />
          </div>

          <div
            onClick={() => {
              JoyTrackHelper.sendAuthEvent('apple', JoyAnalyticsServiceEventAction.start);
            }}
          >
            <AppleLogin
              clientId={process.env.REACT_APP_APPLE_LOGIN_CLIENT_ID || ''}
              redirectURI={process.env.REACT_APP_APPLE_LOGIN_REDIRECT_URI || ''}
              usePopup
              nonce={nonce}
              scope="name email"
              onSuccess={this.handleAppleSignInSuccess}
              onFailure={this.handleAppleSignInFailure}
              render={(renderProps) => {
                if (nonce) {
                  return (
                    <img
                      className={styles.oauthButton}
                      src={appleSignIn}
                      alt="Sign in with Apple"
                      onClick={renderProps.onClick}
                    />
                  );
                }

                return <img className={styles.oauthButton} src={appleSignInDisabled} alt="Sign in with Apple" />;
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      showPassword,
      email,
      emailError,
      password,
      passwordError,
      isBusy,
      redirectToSignUp,
      redirectAtLogin,
      lockEmail
    } = this.state;

    if (redirectToSignUp) {
      return <Redirect push to={{ pathname: ROUTES.signUp, state: { email, lockEmail, redirectAtLogin } }} />;
    }

    return (
      <FormPageTemplate
        legend={this.legend}
        cardActionsExtension={this.cardActionsExtension}
        submitText={showPassword ? 'Sign in' : ' Submit'}
        isBusy={isBusy}
        onSubmit={this.handleSubmit}
        pageExtension={this.pageExtension}
      >
        <EmailInput name="email" value={email} error={emailError} onChange={this.handleChange} />

        {showPassword && (
          <PasswordInput name="password" value={password} error={passwordError} onChange={this.handleChange} />
        )}
      </FormPageTemplate>
    );
  }
}

export default connector(SignIn);
