import { JoyUser } from 'joy-core';
import { RESET_SESSION, SET_CURRENT_USER } from './actions';

interface UserState {
  currentUser: JoyUser | null;
}
const initialState: UserState = {
  currentUser: null
};

export default function user(state = initialState, { type, ...payload }: any): UserState {
  switch (type) {
    case RESET_SESSION:
      return initialState;
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload.user
      };
    default:
      return state;
  }
}
