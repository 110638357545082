import React, { Component } from 'react';
import { JoyGroup, JoyAlbum, JoyAlbumHelper, JoyLogHelper } from 'joy-core';
import { Text, Input, Button } from 'joy-ui';

import ModalTemplate from '@template/Overlays/ModalTemplate';
import SnackbarHelper from '@helpers/SnackbarHelper';

interface IProps {
  open: boolean;
  group: JoyGroup | null;
  onClose: () => void;
  onSubmit: (album: JoyAlbum) => void;
}

interface IState {
  isBusy: boolean;
  albumName: string;
  albumNameError: string;
  albumDescription: string;
  albumDescriptionError: string;
}

class AddNewAlbum extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isBusy: false,
      albumName: '',
      albumNameError: '',
      albumDescription: '',
      albumDescriptionError: ''
    };
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState({ [name]: value, [`${name}Error`]: '' } as Pick<IState, 'albumName' | 'albumDescription'>);
  };

  handleClose = () => {
    this.handleReset(this.props.onClose);
  };

  handleReset = (callback: () => void) => {
    this.setState({ albumName: '', albumNameError: '', albumDescription: '', albumDescriptionError: '' }, callback);
  };

  handleAddAlbum = () => {
    this.setState({ isBusy: true }, async () => {
      const { group, onSubmit } = this.props;
      const { albumName, albumDescription } = this.state;

      if (this.checkFormErrors() && group) {
        try {
          const newAlbum = await JoyAlbumHelper.create(albumName, group.id, [], albumDescription);

          if (newAlbum) {
            this.handleReset(() => {
              onSubmit(newAlbum);
            });
          } else {
            throw Error('Invalid result.');
          }
        } catch (error) {
          JoyLogHelper.error({
            error,
            groupId: group.id,
            data: { albumName, albumDescription },
            place: 'AddNewAlbum.handleAddAlbum'
          });

          SnackbarHelper.showError('Could not create the album! Server communication failed!');
        }
      }

      this.setState({ isBusy: false });
    });
  };

  checkFormErrors(): boolean {
    const { albumName, albumDescription } = this.state;
    const newState: any = {};

    if (albumName.length === 0) {
      newState.albumNameError = 'Cannot be blank';
    } else if (albumName.length < 3) {
      newState.albumNameError = 'Enter at least 3 characters';
    } else if (albumName.length > 100) {
      newState.albumNameError = 'Enter 100 or less characters';
    }

    if (albumDescription.length > 300) {
      newState.albumDescriptionError = 'Enter 300 or less characters';
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
      return false;
    }

    return true;
  }

  render() {
    const {
      state: { isBusy, albumName, albumNameError, albumDescription, albumDescriptionError },
      props: { open }
    } = this;

    return (
      <ModalTemplate
        open={open}
        legend={<Text variant="heading">Create album</Text>}
        onClose={this.handleClose}
        cardActions={
          <Button color="primary" isBusy={isBusy} disabled={!albumName} onClick={this.handleAddAlbum}>
            Next
          </Button>
        }
      >
        <Input
          name="albumName"
          label="Title"
          placeholder="Add a title"
          defaultValue={albumName}
          error={albumNameError}
          onChange={this.handleChange}
        />

        <Input
          name="albumDescription"
          label="Description"
          placeholder="Add a description"
          defaultValue={albumDescription}
          error={albumDescriptionError}
          onChange={this.handleChange}
        />
      </ModalTemplate>
    );
  }
}

export default AddNewAlbum;
