import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  JoyAlbum,
  JoyField,
  JoyFieldObject,
  JoyUploadHelper,
  JoyUploadService,
  JoyUploadSessionType,
  JoyUploadServiceUploadType,
  JoyMediumHelper,
  JoyObjectObserver,
  JoyStatus,
  JoyLogHelper
} from 'joy-core';
import { Button } from 'joy-ui';

import SnackbarTemplate from '@template/Overlays/SnackbarTemplate';
import UploadQueue, { UploadItemProps } from '@components/UploadQueue';
import {
  setUploadCount,
  setUploadedCount,
  addToUploadedCount,
  setUploadingItems,
  removeUploadingItem,
  setUploadingTasks,
  removeUploadingTask,
  setUploadSession,
  removeUploadClient
} from '@utils/redux/upload/actions';
import { UploadState } from '@utils/redux/upload/reducers';
import { RootState } from '@utils/redux/store';
import { MediaType } from '@utils/types';
import UrlHelper from '@helpers/UrlHelper';
import SnackbarHelper from '@helpers/SnackbarHelper';
import UploadModal from './UploadModal';
import UploadLocationModal from './UploadLocationModal';
import AddNewAlbum from './AddNewAlbum/AddNewAlbum';

const mapStateToProps = ({ user, media, upload }: RootState) => ({
  currentUser: user.currentUser,
  currentGroup: media.currentGroup,
  currentAlbum: media.currentAlbum,
  currentMedia: media.currentMedia,
  groups: media.groups,
  albums: media.albums,
  people: media.people,
  uploadState: upload
});

const mapDispatchToProps = {
  setUploadCount,
  setUploadedCount,
  addToUploadedCount,
  setUploadingItems,
  removeUploadingItem,
  setUploadingTasks,
  removeUploadingTask,
  setUploadSession,
  removeUploadClient
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux {
  open: boolean;
  onClose: () => void;
  loadAlbums: () => void;
  onUploadEnd?: () => void;
}

interface IState {
  step: 'closed' | 'selectMedia' | 'selectLocation' | 'addAlbum' | 'uploadMedia' | 'uploadToNewAlbum';
  uploadGroupId: string;
  uploadAlbumId: string;
  uploadFiles: FileList | null;
  showUploadQueue: boolean;
}

class UploadManager extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      step: 'closed',
      uploadGroupId: '',
      uploadAlbumId: '',
      uploadFiles: null,
      showUploadQueue: false
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const { open: prevOpen } = prevProps;
    const { open } = this.props;

    if (!prevOpen && open) {
      this.setState({ step: 'selectMedia', uploadGroupId: '', uploadAlbumId: '', uploadFiles: null });
    }
  }

  handleCloseUploadManager = () => {
    const { onClose } = this.props;

    this.setState(
      {
        step: 'closed'
      },
      onClose
    );
  };

  handleSelectedFilesForUpload = (files: FileList | null) => {
    if (Number(files?.length) > 200) {
      SnackbarHelper.showWarning(
        `You've tried to upload ${files?.length} items. Please upload less then 200 items at once!`
      );

      JoyLogHelper.info({ message: `User tried to upload ${files?.length}` });
    } else {
      this.setState({ step: 'selectLocation', uploadFiles: files });
    }
  };

  handleSelectedUploadLocation = (location: string) => {
    const { currentGroup } = this.props;

    switch (location) {
      case 'group':
        this.setState({ step: 'uploadMedia', uploadGroupId: currentGroup?.id || '' }, this.handleAddMedia);
        break;
      case 'newAlbum':
        this.setState({ step: 'addAlbum' });
        break;
      case '':
        break;
      default:
        this.setState(
          { step: 'uploadMedia', uploadGroupId: currentGroup?.id || '', uploadAlbumId: location },
          this.handleAddMedia
        );
        break;
    }
  };

  handleAddMedia = async () => {
    const {
      state: { step, uploadGroupId, uploadAlbumId, uploadFiles },
      props: {
        currentUser,
        uploadState,
        setUploadCount,
        setUploadedCount,
        addToUploadedCount,
        setUploadingItems,
        setUploadingTasks,
        removeUploadingItem,
        removeUploadingTask,
        setUploadSession
      }
    } = this;

    if (uploadFiles) {
      this.handleCloseUploadManager();

      const filesCount = uploadFiles.length || 0;
      let mediaItemIds: Array<string>;

      JoyLogHelper.info({ message: `Upload initiated for ${filesCount} items.` });

      let uploadKey = uploadGroupId;
      let sessionType = JoyUploadSessionType.mediumsAddedToGroup;

      if (uploadAlbumId) {
        uploadKey = uploadAlbumId;
        sessionType =
          step === 'uploadToNewAlbum' ? JoyUploadSessionType.albumCreated : JoyUploadSessionType.mediumsAddedToAlbum;
      }

      if (uploadState[uploadKey]) {
        setUploadCount(uploadKey, uploadState[uploadKey].uploadCount + filesCount);
      } else {
        setUploadCount(uploadKey, filesCount);
        setUploadedCount(uploadKey, 0);
      }

      const { id: sessionId, mediumIds } = await JoyUploadHelper.createSession(
        sessionType,
        [],
        filesCount,
        uploadGroupId,
        uploadAlbumId || undefined
      );

      setUploadSession(uploadKey, sessionId);
      mediaItemIds = mediumIds;

      for (let i = 0; i < filesCount; i++) {
        const mediaId = mediaItemIds[i];
        const buffer = await uploadFiles[i].arrayBuffer();
        const fileName = uploadFiles[i].name;
        const mimeType = uploadFiles[i].type;
        const extension = fileName.split('.').pop();
        let type: MediaType;

        switch (mimeType.split('/')[0]) {
          case 'image':
            type = MediaType.image;
            break;
          case 'video':
            type = MediaType.video;
            break;
          default:
            type = MediaType.other;
            break;
        }

        if (type === MediaType.other) {
          setUploadingItems(uploadKey, {
            [mediaId]: {
              thumbnailUrl: '',
              type,
              name: fileName,
              status: `Failed. File type '${extension}' not supported`
            }
          });
        } else {
          let data: JoyFieldObject = {
            [JoyField.user]: currentUser?.id,
            [JoyField.medium]: mediaId,
            [JoyField.data]: buffer,
            [JoyField.extension]: extension,
            [JoyField.mimeType]: mimeType
          };

          setUploadingItems(uploadKey, {
            [mediaId]: {
              thumbnailUrl: UrlHelper.createObjectUrl(uploadFiles[i]),
              type,
              name: fileName,
              status: 'Waiting for upload'
            }
          });

          try {
            const uploadTask = JoyUploadService.instance.upload(
              JoyUploadServiceUploadType.media,
              data,
              async (result: any) => {
                const { uploadState } = this.props;
                const uploadingItems = this.uploadingItemsAsObject(uploadKey, uploadState);

                if (uploadingItems) {
                  const item = uploadingItems[mediaId];

                  if (typeof result === 'string') {
                    if (uploadingItems[mediaId].progress === 100) {
                      const medium = await JoyMediumHelper.get(mediaId, uploadGroupId);
                      const observer = new JoyObjectObserver((updates) => {
                        if (updates.includes(JoyField.status) && medium.status === JoyStatus.processed) {
                          const { medium, observer } = uploadingItems[mediaId];

                          if (medium && observer) {
                            medium.removeObserver(observer);
                          }

                          removeUploadingItem(uploadKey, mediaId);
                          addToUploadedCount(uploadKey);
                        }

                        this.checkUploadState(uploadKey);
                      });
                      medium.addObserver(observer);

                      setUploadingItems(uploadKey, {
                        [mediaId]: { ...item, status: 'Processing...', medium, observer, progress: undefined }
                      });
                      removeUploadingTask(uploadKey, mediaId);
                    }
                  } else if (result?.name === 'FirebaseError') {
                    if (result?.code === 'storage/canceled') {
                      removeUploadingItem(uploadKey, mediaId);
                      removeUploadingTask(uploadKey, mediaId);
                      addToUploadedCount(uploadKey);
                    } else {
                      setUploadingItems(uploadKey, {
                        [mediaId]: { ...item, status: 'Failed to upload.', progress: undefined }
                      });
                    }

                    await this.checkUploadState(uploadKey);
                  }
                }
              },
              (progress) => {
                const { uploadState } = this.props;
                const uploadingItems = this.uploadingItemsAsObject(uploadKey, uploadState);

                if (uploadingItems) {
                  const item = uploadingItems[mediaId];
                  setUploadingItems(uploadKey, { [mediaId]: { ...item, progress } });
                }
              }
            );

            // start only the first 3 upload items
            if (i > 2) {
              uploadTask.suspend();
            }

            setUploadingTasks(uploadKey, { [mediaId]: uploadTask });
          } catch (error) {
            JoyLogHelper.error({ error, data, uploadKey, sessionType, place: 'UploadManager.handleAddMedia' });

            const { uploadState } = this.props;

            if (uploadState[uploadKey]) {
              let { uploadingItems } = uploadState[uploadKey];

              if (uploadingItems) {
                const item = uploadingItems[mediaId];
                delete item.progress;

                setUploadingItems(uploadKey, { [mediaId]: { ...item, status: 'Failed to upload.' } });
              }
            }
          }
        }
      }
    }
  };

  checkUploadState = async (uploadKey: string) => {
    const { uploadState, removeUploadClient, onUploadEnd } = this.props;

    if (uploadState[uploadKey]) {
      const { uploadCount, uploadedCount, uploadSessions, uploadingTasks } = uploadState[uploadKey];

      if (uploadCount === uploadedCount) {
        for (let i = 0; i < uploadSessions.length; i++) {
          try {
            await JoyUploadHelper.closeSession(uploadSessions[i]);
          } catch (error) {
            JoyLogHelper.error({
              error,
              uploadKey,
              uploadSession: uploadSessions[i],
              place: 'UploadManager.checkUploadState'
            });
          }
        }

        removeUploadClient(uploadKey);

        if (onUploadEnd) {
          onUploadEnd();
        }
      } else if (uploadingTasks) {
        try {
          Object.values(uploadingTasks)[0]?.resume();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  uploadingItemsAsObject = (uploadKey: string, uploadState: UploadState) => {
    if (uploadState[uploadKey]) {
      const { uploadingItems } = uploadState[uploadKey];

      return uploadingItems;
    }

    return null;
  };

  handleAddAlbum = (album: JoyAlbum) => {
    const { currentGroup, loadAlbums } = this.props;

    this.setState({ step: 'uploadToNewAlbum', uploadGroupId: currentGroup?.id || '', uploadAlbumId: album.id }, () => {
      loadAlbums();
      this.handleAddMedia();
    });
  };

  toggleUploadQueue = () => {
    this.setState((prevState) => ({ showUploadQueue: !prevState.showUploadQueue }));
  };

  get uploadProgress() {
    const { uploadState } = this.props;

    let wholeUploadCount = 0;
    let wholeUploadedCount = 0;
    let progress = 0;

    Object.entries(uploadState).forEach(([key, { uploadCount, uploadedCount }]) => {
      wholeUploadCount += uploadCount;
      wholeUploadedCount += uploadedCount;
    });

    if (wholeUploadCount > 0) {
      progress = (100 * wholeUploadedCount) / wholeUploadCount;
    }

    return { uploadCount: wholeUploadCount, uploadedCount: wholeUploadedCount, progress };
  }

  get parsedUploadingItems(): Array<UploadItemProps> {
    const { currentGroup, uploadState, removeUploadingItem, addToUploadedCount } = this.props;
    const parsedItems: Array<UploadItemProps> = [];

    Object.entries(uploadState).forEach(([uploadKey, data]) => {
      const { uploadingItems, uploadingTasks } = data;

      if (uploadingItems) {
        Object.entries(uploadingItems).forEach(([id, data]) => {
          parsedItems.push({
            id,
            ...data,
            onCancel: async () => {
              try {
                if (uploadingTasks) {
                  const task = uploadingTasks[id];

                  if (task) {
                    task.cancel();
                  }
                }

                if (uploadingItems && currentGroup) {
                  const item = uploadingItems[id];

                  if (item && typeof item.progress === 'undefined') {
                    removeUploadingItem(uploadKey, id);
                    addToUploadedCount(uploadKey);
                  }

                  await this.checkUploadState(uploadKey);

                  await JoyMediumHelper.delete(id, currentGroup.id);
                }
              } catch (error) {
                JoyLogHelper.error({
                  error,
                  uploadKey,
                  groupId: currentGroup?.id,
                  mediaId: id,
                  place: 'UploadManager.onCancel'
                });
              }
            }
          });
        });
      }
    });

    return parsedItems;
  }

  render() {
    const {
      state: { step, showUploadQueue },
      props: { open, albums, currentGroup }
    } = this;

    const { uploadCount, uploadedCount, progress } = this.uploadProgress;

    return (
      <>
        <UploadModal
          open={open && step === 'selectMedia'}
          onClose={this.handleCloseUploadManager}
          onFileSelect={this.handleSelectedFilesForUpload}
        />

        <UploadLocationModal
          open={open && step === 'selectLocation'}
          albums={albums}
          onClose={this.handleCloseUploadManager}
          onSelect={this.handleSelectedUploadLocation}
        />

        <AddNewAlbum
          open={open && step === 'addAlbum'}
          group={currentGroup}
          onClose={this.handleCloseUploadManager}
          onSubmit={this.handleAddAlbum}
        />

        {showUploadQueue ? (
          <UploadQueue
            open={!!uploadCount}
            label="Upload queue"
            items={this.parsedUploadingItems}
            onClose={this.toggleUploadQueue}
          />
        ) : (
          <SnackbarTemplate
            open={!!uploadCount}
            label={`Uploading... ${uploadCount - uploadedCount} ${'items'} remaining.`}
            actions={
              <Button color="white" variant="transparent" onClick={this.toggleUploadQueue}>
                View
              </Button>
            }
            progress={progress}
          />
        )}
      </>
    );
  }
}

export default connector(UploadManager);
