export interface ClientItemType {
  name: string;
  email: string;
  status?: string;
  dealId?: string;
}

export enum MediaType {
  image = 'image',
  video = 'video',
  other = 'other'
}
