import queryString, { ParsedQuery } from 'query-string';

export default class UrlHelper {
  static parseSearch(search: string): ParsedQuery {
    return queryString.parse(search);
  }

  static createObjectUrl(object: File) {
    return window.URL ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
  }

  static revokeObjectURL(url: string) {
    return window.URL ? window.URL.revokeObjectURL(url) : window.webkitURL.revokeObjectURL(url);
  }
}
