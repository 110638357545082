import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  JoyAnalyticsServiceEventAction,
  JoyAnalyticsServiceEventCategory,
  JoyGroupHelper,
  JoyUserHelper,
  JoyTrackHelper,
  JoyLogHelper
} from 'joy-core';
import { Button, Input, Text } from 'joy-ui/components';

import FormPageTemplate from '@template/Pages/FormPageTemplate';
import MultiEmailInput from '@components/MultiEmailInput';
import SnackbarHelper from '@helpers/SnackbarHelper';
import { RootState } from '@utils/redux/store';
import { ROUTES } from '@utils/system';

const mapStateToProps = ({ user }: RootState) => ({
  currentUser: user.currentUser
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IState {
  step: 'group' | 'people';
  group: string;
  groupError: string;
  groupId: string | null;
  emails: Array<string>;
  isBusy: boolean;
  loadingGroup: boolean;
  showSkip: boolean;
}

interface IProps extends PropsFromRedux, RouteComponentProps {}

class Onboarding extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      step: 'group',
      group: '',
      groupError: '',
      groupId: null,
      emails: [],
      isBusy: false,
      loadingGroup: true,
      showSkip: false
    };
  }

  componentDidMount() {
    const { history } = this.props;

    if (!history.location.state) {
      history.push(ROUTES.home);
    }

    JoyTrackHelper.sendScreensEvent('onb_create_group');

    this.loadUserGroups();
  }

  loadUserGroups() {
    const { currentUser } = this.props;

    if (currentUser) {
      this.setState({ loadingGroup: true }, async () => {
        const userGroups = await JoyUserHelper.getGroups(currentUser.id, null, null);

        if (userGroups.length > 0) {
          this.setState({ loadingGroup: false, showSkip: true });
        } else {
          this.setState({ loadingGroup: false, showSkip: false });
        }
      });
    }
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState({ [name]: value, [`${name}Error`]: '' } as Pick<IState, 'group'>);
  };

  handleEmailsChange = (emails: Array<string>) => {
    this.setState({ emails });
  };

  handleCreateGroup = () => {
    this.setState({ isBusy: true }, async () => {
      const { group } = this.state;

      if (group.length < 3) {
        this.setState({ groupError: 'Enter at least 3 characters', isBusy: false });
        return false;
      }

      try {
        const newGroup = await JoyGroupHelper.create(group);

        if (newGroup) {
          this.setState({ isBusy: false, step: 'people', groupId: newGroup.id }, () => {
            JoyTrackHelper.sendScreensEvent('onb_add_contacts');
          });
        } else {
          throw Error('Invalid result.');
        }
      } catch (error) {
        JoyLogHelper.error({ error, groupName: group, place: 'Onboarding.handleCreateGroup' });

        SnackbarHelper.showError('Could not create the group! Server communication failed!');

        this.setState({ isBusy: false });
      }
    });
  };

  handleAddPeople = () => {
    this.setState({ isBusy: true }, async () => {
      const { groupId, emails } = this.state;

      if (groupId && emails.length > 0) {
        try {
          const result = await JoyGroupHelper.addUsers(groupId, emails);
          const resultLength = result.length;

          if (result.length === 0) {
            this.props.history.push(ROUTES.home);

            JoyTrackHelper.sendEvent(
              JoyAnalyticsServiceEventCategory.group_members,
              {
                count: emails.length,
                groupId
              },
              JoyAnalyticsServiceEventAction.add
            );
          } else if (resultLength !== emails.length) {
            const notIncludedEmails = result.map(([email, message]) => email).join(', ');

            JoyTrackHelper.sendEvent(
              JoyAnalyticsServiceEventCategory.group_members,
              {
                count: emails.length - notIncludedEmails.length,
                groupId
              },
              JoyAnalyticsServiceEventAction.add
            );

            SnackbarHelper.showError(`Could not add the following emails: ${notIncludedEmails}`);
            JoyLogHelper.error({ groupId, emails, place: 'Onboarding.handleAddPeople' });
          } else {
            throw Error('Invalid result.');
          }
        } catch (error) {
          JoyLogHelper.error({ error, groupId, emails, place: 'Onboarding.handleAddPeople' });

          SnackbarHelper.showError('Could not add emails to group! Server communication failed!');
        } finally {
          this.setState({ isBusy: false });
        }
      }
    });
  };

  handleSkip = () => {
    this.props.history.push(ROUTES.home);
  };

  get legend() {
    const { step, group } = this.state;
    return step === 'group' ? (
      <Text>Create your first group.</Text>
    ) : (
      <Text>
        <b>{group}</b> has been created. Add some people now.
      </Text>
    );
  }

  get cardActionsExtension() {
    const { step, isBusy, emails, showSkip, loadingGroup } = this.state;

    return step === 'group' ? (
      <div style={{ display: 'flex' }}>
        {showSkip && (
          <Button color="secondary" variant="outlined" isBusy={loadingGroup} onClick={this.handleSkip}>
            Skip
          </Button>
        )}
        &nbsp;&nbsp;&nbsp;
        <Button color="primary" isBusy={isBusy} onClick={this.handleCreateGroup}>
          Create
        </Button>
      </div>
    ) : (
      <div style={{ display: 'flex' }}>
        <Button color="secondary" variant="outlined" onClick={this.handleSkip}>
          Skip
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button color="primary" isBusy={isBusy} disabled={emails.length === 0} onClick={this.handleAddPeople}>
          Add
        </Button>
      </div>
    );
  }

  get pageContent() {
    const { step, group, groupError, emails } = this.state;

    return step === 'group' ? (
      <>
        <Input
          name="group"
          label="Group Name"
          placeholder="Add name or subject"
          value={group}
          error={groupError}
          onChange={this.handleChange}
        />

        <br />
        <Text color="muted" variant="body">
          Give your group a name. It can be anything you like; for your family, friends or with just your best friend.
        </Text>
        <br />
        <br />
      </>
    ) : (
      <MultiEmailInput emails={emails} onChange={this.handleEmailsChange} />
    );
  }

  render() {
    return (
      <FormPageTemplate legend={this.legend} cardActionsExtension={this.cardActionsExtension}>
        {this.pageContent}
      </FormPageTemplate>
    );
  }
}

export default connector(Onboarding);
