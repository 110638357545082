import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Text } from 'joy-ui/components';

import { RootState } from '@utils/redux/store';
import styles from './EmptyGroup.module.scss';

const mapStateToProps = ({ user, system, media }: RootState) => ({
  currentUser: user.currentUser,
  currentGroup: media.currentGroup,
  currentAlbum: media.currentAlbum,
  albums: media.albums,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux {
  // onCreateAlbum: () => void;
  onAddMedia: () => void;
}

class EmptyGroup extends Component<IProps> {
  render() {
    const {
      // onCreateAlbum,
      onAddMedia
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <Text variant="heading">
          There are no albums or media <br /> uploaded to this group yet.
        </Text>
        <br />
        <Text variant="body">Create new albums or share photos with your private groups.</Text>
        <br />

        {/* <div className={styles.actions}> */}
        {/* <Button variant="transparent" color="secondary" className={styles.actionButton} onClick={onCreateAlbum}>
            Create an album
          </Button> */}
        <Button variant="filled" color="secondary" className={styles.actionButton} onClick={onAddMedia}>
          Add memories
        </Button>
        {/* </div> */}
      </div>
    );
  }
}

export default connector(EmptyGroup);
