import React, { Component } from 'react';
import { ModalBase, Button, IconButton, Text, LoadingLoop } from 'joy-ui/components';
import { CloseIcon, VideoCameraIcon } from 'joy-ui/icons';

import { UploadingItemType } from '@utils/redux/upload/reducers';
import styles from './UploadQueue.module.scss';

interface IProps {
  open: boolean;
  label?: React.ReactNode;
  items: Array<UploadItemProps>;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export interface UploadItemProps extends UploadingItemType {
  id: string;
  onCancel: () => void;
}

function UploadItem({ id, thumbnailUrl, type, name, status, progress, onCancel }: UploadItemProps) {
  return (
    <div className={styles.uploadItem}>
      <div className={styles.itemThumbnail}>
        {type === 'image' && <img src={thumbnailUrl} alt={name} width={75} height={75} />}
        {type === 'video' && <VideoCameraIcon color="white" size={75} />}
        {type === 'other' && <CloseIcon color="error" size={75} />}
      </div>

      <div className={styles.itemInfo}>
        <Text color="white" variant="body">
          {name}
        </Text>
        <br />
        {typeof progress === 'undefined' ? (
          <Text color="muted" variant="caption">
            {status}
          </Text>
        ) : (
          <>
            <Text color="muted" variant="caption">
              Uploading in progress
            </Text>
            <div className={styles.progressBar}>
              <div className={styles.currentProgress} style={{ width: `${progress}%` }}></div>
            </div>
          </>
        )}
      </div>

      <div className={styles.itemActions}>
        {/*
        // TODO: implement retry in a later release
        <IconButton icon={RetryIcon} color="muted" size={18} onClick={onRetry} />
        */}
        <IconButton icon={CloseIcon} color="muted" size={18} onClick={onCancel} />
      </div>
    </div>
  );
}

class UploadQueue extends Component<IProps> {
  render() {
    const { label, open, items, onClose } = this.props;

    if (!open) {
      return null;
    }

    return (
      <ModalBase basic={false} clickPassthrough>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Text className={styles.label} variant="titleSmall" color="white">
              {label}
            </Text>
            <div className={styles.actions}>
              <Button color="white" variant="transparent" onClick={onClose}>
                Close
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            {items.length === 0 ? (
              <LoadingLoop color="white" className={styles.loadingLoop} />
            ) : (
              items.map((item: UploadItemProps) => <UploadItem key={item.id} {...item} />)
            )}
          </div>
        </div>
      </ModalBase>
    );
  }
}

export default UploadQueue;
