import React, { Component } from 'react';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { JoyAuthService, JoyFieldObject, JoyField, JoyListener, JoyUser, JoyObjectObserver } from 'joy-core';

import GetApp from '@template/GetApp';
import AuthHelper from '@helpers/AuthHelper';
import { ROUTES } from '@utils/system';
import { getGroups } from '@utils/redux/media/actions';

const mapStateToProps = ({ system }) => ({
  browser: system.browser
});

const mapDispatchToProps = {
  getGroups
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux, RouteProps {
  component: React.ComponentType<RouteComponentProps> | any; // FIXME: handle correct types
}

interface IState {
  loading: boolean;
  redirectToSignIn: boolean;
}

class ProtectedRoute extends Component<IProps, IState> {
  private authListener: JoyListener | null;

  constructor(props: IProps) {
    super(props);

    this.state = { loading: true, redirectToSignIn: false };
    this.authListener = null;
  }

  componentDidMount() {
    this.authListener = JoyAuthService.instance.authListener(async (data: JoyFieldObject) => {
      const auth = data[JoyField.auth];

      if (auth) {
        await AuthHelper.setAuthUserData(data[JoyField.user], this.handleUserUpdate);

        this.setState({ loading: false });
      } else {
        this.setState({ redirectToSignIn: true });
      }
    });

    this.authListener?.activate();
  }

  componentWillUnmount() {
    this.authListener?.deactivate();
    this.authListener = null;
  }

  handleUserUpdate = (user: JoyUser) => {
    const userObserver = new JoyObjectObserver((updates) => {
      let check = false;

      for (const update of updates) {
        if (check) {
          break;
        }

        switch (update) {
          case JoyField.groups:
            this.props.getGroups(user.id);
            break;
          case JoyField.name:
          case JoyField.primaryEmail:
          case JoyField.photoUrl:
            AuthHelper.setAuthUserData(user.id, this.handleUserUpdate);
            check = true;
            break;
        }
      }
    });

    user.addObserver(userObserver);
  };

  renderRoute = (props: React.ComponentProps<any>) => {
    const { component: Component } = this.props;

    if (AuthHelper.isAuthenticated) {
      return <Component {...props} />;
    }

    return <Redirect to={{ pathname: ROUTES.signIn, state: { redirectAtLogin: this.props.location?.pathname } }} />;
  };

  render() {
    const { component, browser, ...otherProps } = this.props;

    if (['iOS', 'Android OS'].includes(browser.os)) {
      return <GetApp os={browser.os} />;
    }

    if (this.state.redirectToSignIn) {
      return <Redirect to={{ pathname: ROUTES.signIn, state: { redirectAtLogin: this.props.location?.pathname } }} />;
    }

    if (this.state.loading) {
      return null;
    }

    return <Route {...otherProps} render={this.renderRoute} />;
  }
}

export default connector(ProtectedRoute);
