import React, { Component } from 'react';
import classNames from 'classnames';

import styles from './DropZone.module.scss';

interface IProps {
  children: React.ReactNode;
  onDrop: (files: FileList | null) => void;
}

interface IState {
  hoverEffect: boolean;
}

class DropZone extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hoverEffect: false
    };
  }

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;

    this.props.onDrop(files);

    this.setState({ hoverEffect: false });
  };

  handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ hoverEffect: true });
  };

  handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ hoverEffect: false });
  };

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';

    this.setState({ hoverEffect: true });
  };

  render() {
    const { hoverEffect } = this.state;
    const { children } = this.props;

    return (
      <div
        className={classNames(styles.wrapper, { [styles.hoverEffect]: hoverEffect })}
        onDrop={this.handleDrop}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
        onDragOver={this.handleDragOver}
      >
        {children}
      </div>
    );
  }
}

export default DropZone;
