import React, { Component } from 'react';
import { Text, Button, ArrowCircleUpIcon, FileUploadWrapper } from 'joy-ui';

import ModalTemplate from '@template/Overlays/ModalTemplate';
import DropZone from '@components/DropZone';

import styles from './UploadModal.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFileSelect: (files: FileList | null) => void;
}

class UploadModal extends Component<IProps> {
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;

    this.props.onFileSelect(files);
  };

  render() {
    const { open, onClose, onFileSelect } = this.props;

    return (
      <ModalTemplate open={open} legend={<Text variant="heading">Upload files</Text>} onClose={onClose}>
        <DropZone onDrop={onFileSelect}>
          <div className={styles.uploadArea}>
            <ArrowCircleUpIcon size={110} color="muted" className={styles.uploadIcon} />
            <br />
            <br />
            <Text variant="paragraph">Drag and drop files here to upload</Text>
            <br />
            <Text variant="paragraph" color="disabled">
              or
            </Text>
            <br />
            <FileUploadWrapper name="clientMedia" onChange={this.handleChange}>
              <Button color="secondary" size="large">
                Choose files
              </Button>
            </FileUploadWrapper>
          </div>
        </DropZone>
      </ModalTemplate>
    );
  }
}

export default UploadModal;
