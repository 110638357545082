import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { JoyGroup, JoyUser } from 'joy-core';
import { Avatar, Button, IconButton, LoadingLoop, Text, Drawer } from 'joy-ui/components';
import { AddIcon, ArrowLeftIcon } from 'joy-ui/icons';

import AuthHelper from '@helpers/AuthHelper';
import { setCurrentGroup, getGroups } from '@utils/redux/media/actions';
import { RootState } from '@utils/redux/store';
import { ROUTES } from '@utils/system';
import AddNewGroup from './AddNewGroup';
import styles from './SideNavigation.module.scss';

const mapStateToProps = ({ user, system, media }: RootState) => ({
  currentGroup: media.currentGroup,
  groups: media.groups,
  peopleAvatars: media.peopleAvatars,
  groupsOwners: media.groupsOwners,
  currentUser: user.currentUser,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = { setCurrentGroup, getGroups };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux, RouteComponentProps {
  open: boolean;
  onClose: () => void;
  currentUser: JoyUser | null;
}

interface IState {
  openAddGroup: boolean;
}

class SideNavigation extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openAddGroup: false
    };
  }

  componentDidMount() {
    const { groups, getGroups, currentUser } = this.props;
    const groupsLength = groups ? groups.length : -1;

    if ((groups === null || groupsLength === 0) && currentUser) {
      getGroups(currentUser.id);
    }
  }

  generateUrl(groupId: string | undefined) {
    if (groupId) {
      return ROUTES.homeGroup.replace(':groupId', groupId);
    }

    return '';
  }

  handleSignOut = async () => {
    await AuthHelper.signOut();

    this.props.history.push(ROUTES.signIn);
  };

  openAddGroup = () => {
    this.setState({ openAddGroup: true });
  };

  closeAddGroup = () => {
    this.setState({ openAddGroup: false });
  };

  handleAddGroup = async (group: JoyGroup) => {
    const { currentUser, getGroups, setCurrentGroup } = this.props;

    if (currentUser) {
      this.closeAddGroup();

      await getGroups(currentUser.id, null, null);

      setCurrentGroup(group);
    }
  };

  handleGroupSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let groupId = event.currentTarget.id;

    if (groupId) {
      const { groups, setCurrentGroup } = this.props;

      let newGroup = groups?.find((group) => group.id === groupId);
      if (newGroup) {
        setCurrentGroup(newGroup);
        const link = this.generateUrl(newGroup.id);

        this.props.history.push(link);
      }
    }
  };

  getGroupAvatar(group: JoyGroup) {
    const { users } = group;
    const { peopleAvatars } = this.props;
    const avatars: any = [];
    let peopleAvatarsLength = 0;

    if (peopleAvatars) {
      peopleAvatarsLength = Object.keys(peopleAvatars).length;

      for (const user of users) {
        if (avatars.length >= 4) {
          break;
        }

        if (peopleAvatars[user]) {
          avatars.push(peopleAvatars[user]);
        }
      }
    }

    switch (avatars.length) {
      case 1:
        const { name, photoUrl } = avatars[0];

        return <Avatar name={name} photoUrl={photoUrl} />;
      case 2:
      case 3:
        return (
          <div className={styles.avatarsGrid2}>
            {avatars.slice(0, 2).map(({ name, photoUrl }) => (
              <Avatar
                key={`${name}-${photoUrl}`}
                name={name}
                photoUrl={photoUrl}
                shape="square"
                className={styles.avatars2}
              />
            ))}
          </div>
        );
      case 4:
        return (
          <div className={styles.avatarsGrid4}>
            {avatars.map(({ name, photoUrl }) => (
              <Avatar key={`${name}-${photoUrl}`} name={name} photoUrl={photoUrl} shape="square" size={20} />
            ))}
          </div>
        );
      default:
        if (peopleAvatarsLength > 0) {
          return (
            <div className={styles.avatarsGrid4}>
              {avatars.slice(0, 4).map(({ name, photoUrl }) => (
                <Avatar key={`${name}-${photoUrl}`} name={name} photoUrl={photoUrl} shape="square" size={20} />
              ))}
            </div>
          );
        }

        return <Avatar name={group.name} />;
    }
  }

  render() {
    const { open, onClose, currentUser, groups, groupsOwners, currentGroup } = this.props;
    const { openAddGroup } = this.state;

    let owners = groupsOwners === null ? {} : groupsOwners;

    return (
      <Drawer open={open} onOutsideClick={onClose}>
        <div className={styles.navHeader}>
          <IconButton icon={ArrowLeftIcon} onClick={onClose} className={styles.closeMenu} />

          <div className={styles.userPanel}>
            <Avatar name={currentUser?.name || ''} photoUrl={currentUser?.photoUrl} size={56} />

            <Text variant="titleSmall" className={styles.userName} title={currentUser?.name}>
              {currentUser?.name}
            </Text>
            <Text variant="body" color="disabled" className={styles.userEmail} title={currentUser?.primaryEmail}>
              {currentUser?.primaryEmail}
            </Text>

            <div>
              <Link to={ROUTES.accountInfo}>
                <Button variant="outlined" color="secondary" size="large" onClickCapture={onClose}>
                  Manage account
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.mainContent}>
          <div className={styles.contentTitle}>
            <Text variant="titleSmall" className={styles.userName}>
              Groups
            </Text>

            <IconButton icon={AddIcon} className={styles.addAction} onClick={this.openAddGroup} />
          </div>

          <br />

          {groups ? (
            groups.map((group) => {
              let selected = group.id === currentGroup?.id;

              return (
                <div
                  key={group.id}
                  id={group.id}
                  className={classNames(styles.groupItem, { [styles.selectedGroup]: selected })}
                  onClick={this.handleGroupSelect}
                >
                  {this.getGroupAvatar(group)}

                  <div className={styles.groupDetails}>
                    <Text
                      className={styles.groupName}
                      variant="subtitle"
                      color={selected ? 'white' : 'default'}
                      title={group.name}
                    >
                      {group.name}
                    </Text>
                    <Text
                      className={styles.groupOwner}
                      variant="bodySmall"
                      color={selected ? 'white' : 'disabled'}
                      title={owners[group.id]}
                    >
                      {owners[group.id] && `From ${owners[group.id]}`}
                    </Text>
                  </div>
                </div>
              );
            })
          ) : (
            <LoadingLoop color="disabled" />
          )}
        </div>

        <div className={styles.navFooter}>
          <Text variant="titleSmall" className={styles.signOutAction} onClick={this.handleSignOut}>
            Sign out
          </Text>
        </div>

        <AddNewGroup open={openAddGroup} onClose={this.closeAddGroup} onSubmit={this.handleAddGroup} />
      </Drawer>
    );
  }
}

export default connector(withRouter(SideNavigation));
