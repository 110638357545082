import React, { Component } from 'react';
import classNames from 'classnames';
import { JoyAlbum, JoyAlbumHelper, JoyGroup, JoyMedium, JoyUser, JoyUserRole, JoyLogHelper } from 'joy-core';
import { DropDownItem, DropDownMenu, IconButton, LoadingLoop, Text } from 'joy-ui/components';
import { MoreHorizIcon } from 'joy-ui/icons';

import DialogTemplate from '@template/Overlays/DialogTemplate';
import SnackbarHelper from '@helpers/SnackbarHelper';
import EditAlbum from '../EditAlbum';

import styles from './AlbumList.module.scss';

interface IProps {
  albums: Array<JoyAlbum> | null;
  currentAlbum: JoyAlbum | null;
  currentGroup: JoyGroup | null;
  currentUser: JoyUser | null;
  thumbnailMedia: Array<JoyMedium> | null;
  onSelect: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDelete?: () => void;
  onAllMediaSelect: () => void;
}

interface IState {
  openDeleteAlbum: boolean;
  openEditAlbum: boolean;
  removingAlbums: Array<string>;
}

class AlbumList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openDeleteAlbum: false,
      openEditAlbum: false,
      removingAlbums: []
    };
  }

  handleDeleteAlbum = (response: boolean) => {
    const { currentAlbum, currentGroup, onDelete } = this.props;

    if (response && currentAlbum && currentGroup) {
      this.setState(
        (prevState) => ({
          removingAlbums: [...prevState.removingAlbums, currentAlbum.id]
        }),
        async () => {
          try {
            await JoyAlbumHelper.delete(currentAlbum.id, currentGroup.id);

            if (onDelete) {
              onDelete();
            }

            this.setState((prevState) => ({
              removingAlbums: prevState.removingAlbums.filter((item) => item !== currentAlbum.id)
            }));
          } catch (error) {
            JoyLogHelper.error({
              error,
              albumId: currentAlbum.id,
              groupId: currentGroup.id,
              place: 'AlbumList.handleDeleteAlbum'
            });

            SnackbarHelper.showError('Could not remove album.');
          }
        }
      );
    }
  };

  openDeleteAlbum = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ openDeleteAlbum: true });
  };

  closeDeleteAlbum = () => {
    this.setState({ openDeleteAlbum: false });
  };

  openEditAlbum = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ openEditAlbum: true });
  };

  closeEditAlbum = () => {
    this.setState({ openEditAlbum: false });
  };

  captureAlbumSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const albumId = event.currentTarget.dataset.album || '';
    const { removingAlbums } = this.state;
    const { onSelect } = this.props;

    if (!removingAlbums.includes(albumId)) {
      onSelect(event);
    }
  };

  get groupThumbnail() {
    const { thumbnailMedia } = this.props;

    if (thumbnailMedia) {
      switch (thumbnailMedia.length) {
        case 0:
          return undefined;
        case 1:
          return (
            <div className={styles.grid1} style={{ backgroundImage: `url(${thumbnailMedia[0]?.thumbnailUri})` }}></div>
          );
        case 2:
        case 3:
          return (
            <>
              <div
                className={styles.grid2}
                style={{ backgroundImage: `url(${thumbnailMedia[0]?.thumbnailUri})` }}
              ></div>
              <div
                className={styles.grid2}
                style={{ backgroundImage: `url(${thumbnailMedia[1]?.thumbnailUri})` }}
              ></div>
            </>
          );
        default:
          return (
            <>
              <div
                className={styles.grid4}
                style={{ backgroundImage: `url(${thumbnailMedia[0]?.thumbnailUri})` }}
              ></div>
              <div
                className={styles.grid4}
                style={{ backgroundImage: `url(${thumbnailMedia[1]?.thumbnailUri})` }}
              ></div>
              <div
                className={styles.grid4}
                style={{ backgroundImage: `url(${thumbnailMedia[2]?.thumbnailUri})` }}
              ></div>
              <div
                className={styles.grid4}
                style={{ backgroundImage: `url(${thumbnailMedia[3]?.thumbnailUri})` }}
              ></div>
            </>
          );
      }
    }

    return undefined;
  }

  enableContextMenu = (createdBy: string) => {
    const { currentUser, currentGroup } = this.props;

    if (currentUser && currentGroup) {
      return currentUser.id === createdBy || currentUser.groups[currentGroup.id] === JoyUserRole.owner;
    }

    return false;
  };

  render() {
    const { albums, currentAlbum, currentGroup, onAllMediaSelect } = this.props;
    const { openDeleteAlbum, openEditAlbum, removingAlbums } = this.state;
    const currentAlbumId = currentAlbum?.id;

    return (
      <>
        <div className={styles.wrapper}>
          <Text variant="bodySmall" color="muted" className={styles.title}>
            ALBUMS
          </Text>

          <div className={styles.listContainer}>
            <div
              className={classNames(styles.albumItem, {
                [styles.selectedAlbum]: typeof currentAlbumId === 'undefined'
              })}
              onClick={onAllMediaSelect}
            >
              <div className={styles.groupThumbnail}>{this.groupThumbnail}</div>

              <div
                className={classNames(styles.titleOverlay, {
                  [styles.blurredAlbum]: !!currentAlbumId
                })}
              >
                <Text variant="subtitle" color="white" className={styles.albumTitle}>
                  All Group Media
                </Text>
              </div>
            </div>

            {albums?.map((item) => {
              const thumbnail = item?.coverMedium?.thumbnailUri || '';
              const isSelected = item.id === currentAlbumId;

              return (
                <div
                  key={item.id}
                  className={classNames(styles.albumItem, { [styles.selectedAlbum]: isSelected })}
                  style={{
                    backgroundImage: `url(${thumbnail})`
                  }}
                  data-album={item.id}
                  onClick={this.captureAlbumSelect}
                >
                  <div
                    className={classNames(styles.titleOverlay, {
                      [styles.blurredAlbum]:
                        (currentAlbumId && item.id !== currentAlbumId) || removingAlbums.includes(item.id)
                    })}
                  >
                    {isSelected && this.enableContextMenu(item.createdBy) && (
                      <DropDownMenu
                        anchor={<IconButton icon={MoreHorizIcon} className={styles.optionsMenu} color="white" />}
                      >
                        <DropDownItem label="Edit name" data-value={item.id} onClick={this.openEditAlbum} />
                        <DropDownItem label="Delete album" data-value={item.id} onClick={this.openDeleteAlbum} />
                      </DropDownMenu>
                    )}

                    {removingAlbums.includes(item.id) && <LoadingLoop color="muted" className={styles.loadingLoop} />}

                    <Text variant="subtitle" color="white" className={styles.albumTitle} title={item.name}>
                      {item.name}
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <EditAlbum open={openEditAlbum} group={currentGroup} album={currentAlbum} onClose={this.closeEditAlbum} />

        <DialogTemplate
          open={openDeleteAlbum}
          yesLabel="Yes, delete album"
          noLabel="Cancel, do not delete"
          onClose={this.closeDeleteAlbum}
          onResponse={this.handleDeleteAlbum}
        >
          <Text variant="heading" className={styles.dialogTitle}>
            Delete this album?
          </Text>

          <Text variant="subtitle" className={styles.dialogSubtitle}>
            Deleting this album will remove <b>{currentAlbum?.name}</b> for you and all the subscribers. Content in this
            album will be gone and can't be recovered.
          </Text>
        </DialogTemplate>
      </>
    );
  }
}

export default AlbumList;
