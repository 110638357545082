import React, { Component } from 'react';
import { Text } from 'joy-ui/components';

import MessagePageTemplate from '@template/Pages/MessagePageTemplate';

// TODO: handle display only if all params are received
class VerifyEmail extends Component {
  get legend() {
    return <Text>Your account has been confirmed.</Text>;
  }

  render() {
    return (
      <MessagePageTemplate legend={this.legend}>
        <Text variant="body" color="muted">
          Open the Joy app and sign in.
        </Text>
      </MessagePageTemplate>
    );
  }
}

export default VerifyEmail;
