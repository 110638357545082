import React, { Component } from 'react';
import { JoyGroup, JoyUser, JoyGroupHelper, JoyLogHelper } from 'joy-core';
import { Text, Button } from 'joy-ui';

import ModalTemplate from '@template/Overlays/ModalTemplate';
import MultiEmailInput from '@components/MultiEmailInput';
import SnackbarHelper from '@helpers/SnackbarHelper';

import styles from './AddPeopleModal.module.scss';

interface IProps {
  open: boolean;
  group: JoyGroup | null;
  people: Array<JoyUser> | null;
  onClose: () => void;
  onAddPeople?: () => Promise<void>;
}

interface IState {
  emails: Array<string>;
  isBusy: boolean;
}

class AddPeopleModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      emails: [],
      isBusy: false
    };
  }

  handleEmailsChange = (emails: Array<string>) => {
    this.setState({ emails });
  };

  handleAddPeople = () => {
    this.setState({ isBusy: true }, async () => {
      const { emails } = this.state;
      const { group, onAddPeople } = this.props;

      if (group && emails.length > 0) {
        try {
          const result = await JoyGroupHelper.addUsers(group.id, emails);
          const resultLength = result.length;

          if (resultLength === 0) {
            if (onAddPeople) {
              await onAddPeople();
            }
          } else if (resultLength !== emails.length) {
            const notIncludedEmails = result.map(([email, message]) => email).join(', ');

            SnackbarHelper.showError(`Could not add the following emails: ${notIncludedEmails}`);
            JoyLogHelper.error({
              groupId: group.id,
              emails: notIncludedEmails,
              place: 'AddPeopleModal.handleAddPeople'
            });

            if (onAddPeople) {
              await onAddPeople();
            }
          } else {
            throw Error('Invalid result.');
          }
        } catch (error) {
          JoyLogHelper.error({ error, groupId: group.id, emails, place: 'AddPeopleModal.handleAddPeople' });

          SnackbarHelper.showError('Could not add emails to group! Server communication failed!');
        } finally {
          this.setState({ isBusy: false }, this.handleClose);
        }
      }
    });
  };

  handleClose = () => {
    this.setState({ emails: [] }, this.props.onClose);
  };

  render() {
    const {
      state: { emails, isBusy },
      props: { open }
    } = this;

    return (
      <ModalTemplate open={open} legend={<Text variant="heading">Invite People</Text>} onClose={this.handleClose}>
        <br />
        <MultiEmailInput emails={emails} onChange={this.handleEmailsChange} />

        <Button
          className={styles.button}
          color="primary"
          isBusy={isBusy}
          disabled={emails.length === 0}
          size="large"
          onClick={this.handleAddPeople}
        >
          Add people
        </Button>
      </ModalTemplate>
    );
  }
}

export default AddPeopleModal;
