import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { JoyUserHelper, JoyLogHelper } from 'joy-core';
import { Avatar, Button, Text } from 'joy-ui/components';

import { RootState } from '@utils/redux/store';
import { ROUTES } from '@utils/system';
import AuthHelper from '@helpers/AuthHelper';
import InlineConfirmationInput from '@components/InlineConfirmationInput';
import EditAccount from './EditAccount';
import styles from './InfoContent.module.scss';

const mapStateToProps = ({ user, system }: RootState) => ({
  currentUser: user.currentUser,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux, RouteComponentProps {}

interface IState {
  openEdit: boolean;
}

class InfoContent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openEdit: false
    };
  }

  handleAccountUpdate = async () => {
    const { currentUser } = this.props;

    if (currentUser) {
      await AuthHelper.updateUserState(currentUser.id);
    }

    this.closeEditModal();
  };

  openEditModal = () => {
    this.setState({ openEdit: true });
  };

  closeEditModal = () => {
    this.setState({ openEdit: false });
  };

  handleAccountDelete = async () => {
    const { currentUser, history } = this.props;

    if (currentUser) {
      try {
        await JoyUserHelper.delete(currentUser.id);

        await AuthHelper.signOut();

        history.push(ROUTES.signIn);
      } catch (error) {
        JoyLogHelper.error({ error, userId: currentUser.id, place: 'InfoContent.handleAccountDelete' });
      }
    }
  };

  render() {
    const { openEdit } = this.state;
    const { currentUser } = this.props;

    return (
      <div className={styles.content}>
        <Text className={styles.title} variant="headingSmall">
          Account Information
        </Text>

        <div className={styles.card}>
          <Avatar name={currentUser?.name || ''} photoUrl={currentUser?.photoUrl} size={70} />

          <div className={styles.details}>
            {currentUser && (
              <>
                <Text variant="titleSmall">{currentUser.name}</Text>
                <Text variant="titleSmall">{currentUser.primaryEmail}</Text>
                <Text variant="titleSmall">{currentUser.phone}</Text>
              </>
            )}
          </div>

          <Button variant="outlined" color="secondary" onClick={this.openEditModal}>
            Edit
          </Button>
        </div>

        <div className={styles.placeholder}></div>

        <InlineConfirmationInput onSubmit={this.handleAccountDelete} className={styles.deleteConfirmation} />

        <EditAccount
          open={openEdit}
          user={currentUser}
          onClose={this.closeEditModal}
          onSubmit={this.handleAccountUpdate}
        />
      </div>
    );
  }
}

export default connector(withRouter(InfoContent));
