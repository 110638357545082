import { JoyGroup, JoyAlbum, JoyMedium, JoyUser, JoyAlbumsObserver, JoyMediumsObserver } from 'joy-core';

import {
  RESET_MEDIA,
  SET_CURRENT_GROUP,
  SET_CURRENT_ALBUM,
  SET_CURRENT_MEDIA,
  SET_THUMBNAIL_MEDIA,
  SET_SELECTED_ITEMS,
  CLEAR_SELECTED_ITEMS,
  SET_GROUPS,
  SET_LOADING_GROUPS,
  SET_LOADING_ALBUMS,
  SET_LOADING_MEDIA,
  SET_LOADING_PEOPLE,
  SET_ALBUMS,
  SET_ALBUMS_OBSERVER,
  SET_PEOPLE,
  SET_PEOPLE_AVATARS,
  SET_MEDIUMS_OBSERVER,
  SET_GROUPS_OWNERS
} from './actions';

export interface PeopleAvatarsType {
  [userId: string]: PeopleAvatarType;
}

export interface PeopleAvatarType {
  name: string;
  photoUrl: string;
}

export interface GroupsAvatarsType {
  [groupId: string]: string;
}

interface MediaState {
  currentGroup: JoyGroup | null;
  currentAlbum: JoyAlbum | null;
  currentMedia: Array<JoyMedium>;
  thumbnailMedia: Array<JoyMedium>;
  selectedItems: Array<string>;
  groups: Array<JoyGroup> | null;
  albums: Array<JoyAlbum> | null;
  albumsObserver: JoyAlbumsObserver | null;
  people: Array<JoyUser> | null;
  peopleAvatars: PeopleAvatarsType | null;
  groupsOwners: GroupsAvatarsType | null;
  mediumsObserver: JoyMediumsObserver | null;
  loadingGroups: boolean;
  loadingAlbums: boolean;
  loadingMedia: boolean;
  loadingPeople: boolean;
}

const initialState: MediaState = {
  currentGroup: null,
  currentAlbum: null,
  currentMedia: [],
  thumbnailMedia: [],
  selectedItems: [],
  groups: null,
  albums: null,
  albumsObserver: null,
  people: null,
  peopleAvatars: null,
  groupsOwners: null,
  mediumsObserver: null,
  loadingGroups: true,
  loadingAlbums: true,
  loadingMedia: true,
  loadingPeople: true
};

export default function media(state = initialState, { type, ...payload }: any): MediaState {
  switch (type) {
    case RESET_MEDIA:
      return initialState;
    case SET_GROUPS:
      return {
        ...state,
        groups: payload.groups.sort((a: JoyGroup, b: JoyGroup) => {
          // ASC
          const firstItem = a.name.toLowerCase();
          const secondItem = b.name.toLowerCase();

          if (firstItem < secondItem) return -1;
          if (firstItem > secondItem) return 1;
          return 0;
        })
      };
    case SET_LOADING_GROUPS:
      return {
        ...state,
        loadingGroups: payload.loadingGroups
      };
    case SET_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: payload.currentGroup
      };
    case SET_ALBUMS:
      return {
        ...state,
        albums: payload.albums.sort((a: JoyAlbum, b: JoyAlbum) => {
          // ASC
          const firstItem = a.name.toLowerCase();
          const secondItem = b.name.toLowerCase();

          if (firstItem < secondItem) return -1;
          if (firstItem > secondItem) return 1;
          return 0;
        })
      };
    case SET_ALBUMS_OBSERVER:
      return {
        ...state,
        albumsObserver: payload.albumsObserver
      };
    case SET_LOADING_ALBUMS:
      return {
        ...state,
        loadingAlbums: payload.loadingAlbums
      };
    case SET_CURRENT_ALBUM:
      return {
        ...state,
        currentAlbum: payload.currentAlbum
      };
    case SET_MEDIUMS_OBSERVER:
      return {
        ...state,
        mediumsObserver: payload.mediumsObserver
      };
    case SET_LOADING_MEDIA:
      return {
        ...state,
        loadingMedia: payload.loadingMedia
      };
    case SET_CURRENT_MEDIA:
      return {
        ...state,
        currentMedia: payload.currentMedia.sort((a: JoyMedium, b: JoyMedium) => {
          // DESC
          const firstItem = a.takenAt;
          const secondItem = b.takenAt;

          if (firstItem < secondItem) return 1;
          if (firstItem > secondItem) return -1;
          return 0;
        })
      };
    case SET_THUMBNAIL_MEDIA:
      return {
        ...state,
        thumbnailMedia: payload.thumbnailMedia.sort((a: JoyMedium, b: JoyMedium) => {
          // DESC
          const firstItem = a.takenAt;
          const secondItem = b.takenAt;

          if (firstItem < secondItem) return 1;
          if (firstItem > secondItem) return -1;
          return 0;
        })
      };
    case SET_SELECTED_ITEMS:
      const { selectedItems } = state;
      const { mediaId } = payload;

      return {
        ...state,
        selectedItems: selectedItems.includes(mediaId)
          ? selectedItems.filter((item) => item !== mediaId)
          : [...selectedItems, mediaId]
      };
    case CLEAR_SELECTED_ITEMS:
      return { ...state, selectedItems: [] };
    case SET_PEOPLE:
      return {
        ...state,
        people: payload.people.sort((a: JoyUser, b: JoyUser) => {
          // ASC
          const firstItem = a.name.toLowerCase();
          const secondItem = b.name.toLowerCase();

          if (firstItem < secondItem) return -1;
          if (firstItem > secondItem) return 1;
          return 0;
        })
      };
    case SET_PEOPLE_AVATARS:
      return {
        ...state,
        peopleAvatars: {
          ...state.peopleAvatars,
          ...payload.avatars
        }
      };
    case SET_GROUPS_OWNERS:
      return {
        ...state,
        groupsOwners: {
          ...state.groupsOwners,
          ...payload.groupsOwners
        }
      };
    case SET_LOADING_PEOPLE:
      return {
        ...state,
        loadingPeople: payload.loadingPeople
      };
    default:
      return state;
  }
}
