import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { NativeLink, JoyLogo, IconButton } from 'joy-ui/components';

import Header from '@template/Header';
import Footer from '@template/Footer';
import SideNavigation from '@template/SideNavigation';
import AuthHelper from '@helpers/AuthHelper';
import { RootState } from '@utils/redux/store';
import { ROUTES } from '@utils/system';
import styles from './NavigationViewTemplate.module.scss';
import { MenuIcon } from 'joy-ui/icons';

const mapStateToProps = ({ system }: RootState) => ({
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux, RouteComponentProps {
  header?: React.ReactNode;
  headerContent?: React.ReactNode;
  headerLeftContent?: React.ReactNode;
  useLocation?: boolean;
  logoExtension?: 'portrait' | null;
  footer?: React.ReactNode;
  sideNavigation?: React.ReactNode;
  children: React.ReactNode;
  noWrapper?: boolean;
  fixedHeader?: boolean;
  openDrawerAtStart?: boolean;
}

interface IState {
  openDrawer: boolean;
}

class NavigationViewTemplate extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { openDrawerAtStart = true } = props;
    const openDrawer = localStorage.getItem('joyNavigationDrawer');

    this.state = {
      openDrawer: openDrawer ? openDrawer === 'true' : openDrawerAtStart
    };
  }

  openDrawer = () => {
    this.setState({ openDrawer: true }, () => {
      localStorage.setItem('joyNavigationDrawer', 'true');
    });
  };

  closeDrawer = () => {
    this.setState({ openDrawer: false }, () => {
      localStorage.setItem('joyNavigationDrawer', 'false');
    });
  };

  handleSignOut = async () => {
    await AuthHelper.signOut();

    this.props.history.push(ROUTES.signIn);
  };

  get headerLeftContent() {
    return <IconButton icon={MenuIcon} onClick={this.openDrawer} className={styles.menuButton} />;
  }

  render() {
    const {
      header,
      headerContent,
      headerLeftContent,
      useLocation,
      logoExtension,
      footer,
      sideNavigation,
      children,
      noWrapper,
      fixedHeader,
      isMobile
      // isTablet
    } = this.props;

    return (
      <>
        {header || (
          <Header
            leftContent={headerLeftContent || this.headerLeftContent}
            content={headerContent}
            useLocation={useLocation}
            logoExtension={logoExtension}
            fixedHeader={fixedHeader}
          />
        )}

        {noWrapper ? (
          <>
            <div className={classNames({ [styles.contentFiller]: fixedHeader, [styles.fixedHeader]: fixedHeader })} />

            {children}
          </>
        ) : (
          <div
            className={classNames(styles.wrapper, {
              [styles.fixedHeader]: fixedHeader,
              [styles.wrapperMobile]: isMobile
            })}
          >
            {children}
          </div>
        )}

        {footer || (
          <Footer
            leftColumn={
              <>
                <NativeLink href={`${process.env.REACT_APP_JOY_LINK}/pages/terms-of-service`} target="_blank">
                  Terms
                </NativeLink>
                <NativeLink href={`${process.env.REACT_APP_JOY_LINK}/pages/privacy-policy`} target="_blank">
                  Privacy
                </NativeLink>
              </>
            }
            middleColumn={<JoyLogo />}
            rightColumn={
              <>
                <NativeLink href={`${process.env.REACT_APP_JOY_LINK}/pages/frequently-asked-questions`} target="_blank">
                  FAQ
                </NativeLink>
                <NativeLink href={`${process.env.REACT_APP_JOY_LINK}/pages/help-center`} target="_blank">
                  Help Center
                </NativeLink>
              </>
            }
          />
        )}

        {sideNavigation || <SideNavigation open={this.state.openDrawer} onClose={this.closeDrawer} />}
      </>
    );
  }
}

export default connector(withRouter(NavigationViewTemplate));
