import React from 'react';
import { Text } from 'joy-ui/components';

import styles from './NoGroup.module.scss';
import { AddIcon } from 'joy-ui';

export default function NoGroup() {
  return (
    <div className={styles.wrapper}>
      <br />
      <Text variant="heading">You are not part of any group.</Text>
      <br />
      <br />
      <Text variant="subtitle" className={styles.subtitle}>
        Please open the side menu on the left and add a new group using the "<AddIcon />" button.
      </Text>
      <br />
    </div>
  );
}
