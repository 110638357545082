import React, { Component } from 'react';
import { ModalBase, IconButton } from 'joy-ui/components';
import { CloseIcon } from 'joy-ui/icons';

import styles from './ModalTemplate.module.scss';

interface IProps {
  children?: React.ReactNode;
  cardActions?: React.ReactNode;
  legend?: React.ReactNode;
  open: boolean;
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent) => void;
}

interface IState {}

class ModalTemplate extends Component<IProps, IState> {
  // constructor(props: ModalPageProps) {
  //   super(props);
  // }

  handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      //   this.props.onSubmit(event);
    }
  };

  render() {
    const { children, cardActions, open, legend, onClose } = this.props;

    if (!open) {
      return null;
    }

    return (
      <ModalBase>
        <div className={styles.wrapper}>
          <div className={styles.card} onKeyDownCapture={this.handleKeyDown}>
            <IconButton className={styles.closeButton} icon={CloseIcon} color="muted" onClick={onClose} />

            <div className={styles.legend}>{legend}</div>

            {children}

            <div className={styles.cardActions}>{cardActions}</div>
          </div>
        </div>
      </ModalBase>
    );
  }
}

export default ModalTemplate;
