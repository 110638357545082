import React, { Component } from 'react';
import { JoyLogo } from 'joy-ui/components';

import styles from './MessagePageTemplate.module.scss';

interface IProps {
  children?: React.ReactNode;
  legend?: React.ReactNode;
}

class MessagePageTemplate extends Component<IProps> {
  render() {
    const { children, legend } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <a href={process.env.REACT_APP_JOY_LINK}>
            <JoyLogo />
          </a>

          <div className={styles.legend}>{legend}</div>

          {children}
        </div>
      </div>
    );
  }
}

export default MessagePageTemplate;
