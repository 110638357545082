import React, { Component } from 'react';
import { JoyGroup, JoyAlbum, JoyLogHelper } from 'joy-core';
import { Text, Input, Button } from 'joy-ui';

import ModalTemplate from '@template/Overlays/ModalTemplate';

interface IProps {
  open: boolean;
  group: JoyGroup | null;
  album: JoyAlbum | null;
  onClose: () => void;
  onSubmit?: (album: JoyAlbum) => void;
}

interface IState {
  isBusy: boolean;
  albumName: string;
  albumNameError: string;
  albumDescription: string;
  albumDescriptionError: string;
}

class EditAlbum extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { name = '', description = '' } = props.album || {};

    this.state = {
      isBusy: false,
      albumName: name,
      albumNameError: '',
      albumDescription: description,
      albumDescriptionError: ''
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const { album } = this.props;

    if (album !== prevProps.album) {
      if (album) {
        this.setState({ albumName: album.name, albumDescription: album.description });
      } else {
        this.setState({ albumName: '', albumDescription: '' });
      }
    }
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState({ [name]: value, [`${name}Error`]: '' } as Pick<IState, 'albumName' | 'albumDescription'>);
  };

  handleClose = () => {
    this.handleReset(this.props.onClose);
  };

  handleReset = (callback: () => void) => {
    const { name = '', description = '' } = this.props.album || {};

    this.setState(
      { albumName: name, albumNameError: '', albumDescription: description, albumDescriptionError: '' },
      callback
    );
  };

  handleUpdateAlbum = () => {
    this.setState({ isBusy: true }, async () => {
      const { album, onSubmit } = this.props;

      if (this.checkFormErrors() && album) {
        const { albumName, albumDescription } = this.state;

        try {
          await album.setName(albumName);
          await album.setDescription(albumDescription);

          if (onSubmit) {
            onSubmit(album);
          }
        } catch (error) {
          JoyLogHelper.error({
            error,
            albumID: album.id,
            data: { albumName, albumDescription },
            place: 'EditAlbum.handleUpdatedAlbum'
          });
        }
      }

      this.setState({ isBusy: false }, this.handleClose);
    });
  };

  checkFormErrors(): boolean {
    const { albumName, albumDescription } = this.state;
    const newState: any = {};

    if (albumName.length === 0) {
      newState.albumNameError = 'Cannot be blank';
    } else if (albumName.length < 3) {
      newState.albumNameError = 'Enter at least 3 characters';
    } else if (albumName.length > 100) {
      newState.albumNameError = 'Enter 100 or less characters';
    }

    if (albumDescription && albumDescription.length > 300) {
      newState.albumDescriptionError = 'Enter 300 or less characters';
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);

      return false;
    }

    return true;
  }

  render() {
    const {
      state: { isBusy, albumName, albumNameError, albumDescription, albumDescriptionError },
      props: { open }
    } = this;

    return (
      <ModalTemplate
        open={open}
        legend={<Text variant="heading">Edit album</Text>}
        onClose={this.handleClose}
        cardActions={
          <Button color="primary" isBusy={isBusy} disabled={!albumName} onClick={this.handleUpdateAlbum}>
            Save changes
          </Button>
        }
      >
        <Input
          name="albumName"
          label="Title"
          placeholder="Add a title"
          defaultValue={albumName}
          error={albumNameError}
          onChange={this.handleChange}
        />

        <Input
          name="albumDescription"
          label="Description"
          placeholder="Add a description"
          defaultValue={albumDescription}
          error={albumDescriptionError}
          onChange={this.handleChange}
        />
      </ModalTemplate>
    );
  }
}

export default EditAlbum;
