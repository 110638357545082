import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from '@sections/Home';
import SignIn from '@sections/Auth/SignIn';
import SignUp from '@sections/Auth/SignUp';
import VerifyEmail from '@sections/Auth/VerifyEmail';
import ResetPassword from '@sections/Auth/ResetPassword';
import ForgotPassword from '@sections/Auth/ForgotPassword';
import Onboarding from '@sections/Onboarding';
import AccountManagement from '@sections/AccountManagement';
import Error404 from '@template/Errors/Error404';
import Snackbar from '@components/Snackbar';
import { ROUTES } from '@utils/system';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <main role="main">
      <BrowserRouter>
        <Switch>
          <Route exact path={ROUTES.signIn} component={SignIn} />
          <Route exact path={ROUTES.signUp} component={SignUp} />
          <Route exact path={ROUTES.verify} component={VerifyEmail} />
          <Route exact path={ROUTES.resetPassword} component={ResetPassword} />
          <Route exact path={ROUTES.forgotPassword} component={ForgotPassword} />

          <ProtectedRoute exact path={ROUTES.onboarding} component={Onboarding} />

          <ProtectedRoute exact path={ROUTES.home} component={Home} />
          <ProtectedRoute exact path={ROUTES.homeGroup} component={Home} />
          <ProtectedRoute exact path={ROUTES.homeGroupMedia} component={Home} />
          <ProtectedRoute exact path={ROUTES.homeGroupAlbum} component={Home} />
          <ProtectedRoute exact path={ROUTES.homeGroupAlbumMedia} component={Home} />

          <ProtectedRoute exact path={ROUTES.accountInfo} component={AccountManagement} />
          {/* <ProtectedRoute exact path={ROUTES.accountPayment} component={AccountManagement} />
          <ProtectedRoute exact path={ROUTES.accountSubscription} component={AccountManagement} />
          <ProtectedRoute exact path={ROUTES.accountOffers} component={AccountManagement} /> */}

          <Route path={ROUTES.error404} component={Error404} />
          <Route path="*" component={Error404} />
        </Switch>
      </BrowserRouter>

      <Snackbar />
    </main>
  );
}

export default App;
