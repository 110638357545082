import { JoyAnalyticsService } from 'joy-core';
import { MixpanelAnalyticsServiceDestination } from 'joy-mixpanel-services';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

export function mixpanelInit() {
  if (MIXPANEL_TOKEN) {
    const instance = new MixpanelAnalyticsServiceDestination(MIXPANEL_TOKEN);

    if (instance) {
      JoyAnalyticsService.add(instance);
    }
  } else {
    console.log('Could not initialize mixpanel service!');
  }
}
