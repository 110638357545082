import { Component } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { IconButton, Text } from 'joy-ui/components';
import { CloseIcon } from 'joy-ui/icons';

import styles from './DatePicker.module.scss';

interface IProps {
  value: Date | string;
  id: string;
  name?: string;
  maxDate?: Date;
  minDate?: Date;
  onChange: (newDate: Date) => void;
}

interface IState {
  showCalendar: boolean;
  calendarPosition: number;
}

class DatePicker extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showCalendar: false,
      calendarPosition: 50
    };
  }

  getPositionDelta() {
    const input = document.getElementById(this.props.id);

    if (input) {
      const parent = input.parentElement;
      let parentHeight = window.innerHeight;

      if (parent) {
        parentHeight = parent.getBoundingClientRect().height;
      }

      const delta = parentHeight - input.getBoundingClientRect().top;

      if (delta < 350) {
        return -270;
      }
    }

    return 50;
  }

  openCalendar = () => {
    this.setState({ showCalendar: true, calendarPosition: this.getPositionDelta() });
  };

  closeCalendar = () => {
    this.setState({ showCalendar: false });
  };

  handleDateSelect = (newDate: Date) => {
    this.props.onChange(newDate);

    this.closeCalendar();
  };

  render() {
    const { showCalendar, calendarPosition } = this.state;
    const { id, name, value, maxDate, minDate } = this.props;

    const stringValue =
      value instanceof Date
        ? value.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        : value === null
        ? ''
        : value;

    const dateValue = value instanceof Date ? value : new Date(value);

    return (
      <div id={id} className={styles.wrapper}>
        <div className={styles.label} onClick={this.openCalendar}>
          {stringValue ? (
            <>
              <Text variant="bodySmall" color="muted">
                Birthday
              </Text>
              <Text className={styles.labelValue} variant="titleSmall">
                {stringValue}
              </Text>
            </>
          ) : (
            <Text className={styles.labelEmpty} variant="titleSmall" color="muted">
              Birthday
            </Text>
          )}
        </div>

        {showCalendar && (
          <div className={styles.calendar} style={{ top: calendarPosition }}>
            <IconButton className={styles.closeIcon} icon={CloseIcon} color="disabled" onClick={this.closeCalendar} />

            <Calendar maxDate={maxDate} minDate={minDate} value={dateValue} onClickDay={this.handleDateSelect} />
          </div>
        )}
      </div>
    );
  }
}

export default DatePicker;
