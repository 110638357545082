import { Component } from 'react';
import { Button, Text } from 'joy-ui';

import styles from './InlineConfirmationInput.module.scss';
import classNames from 'classnames';

interface IProps {
  className?: string;
  onSubmit: () => Promise<void>;
}

interface IState {
  showConfirmation: boolean;
  showError: boolean;
  inputValue: string;
  isBusy: boolean;
}

class InlineConfirmationInput extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showConfirmation: false,
      showError: false,
      inputValue: '',
      isBusy: false
    };
  }

  openConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  closeConfirmation = () => {
    this.setState({ showConfirmation: false, showError: false, inputValue: '', isBusy: false });
  };

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    this.setState({ inputValue: value, showError: false });
  };

  handleDelete = () => {
    const { onSubmit } = this.props;
    const { inputValue } = this.state;

    if (inputValue === 'DELETE') {
      this.setState({ isBusy: true }, async () => {
        await onSubmit();
      });
    } else {
      this.setState({ showError: true });
    }
  };

  render() {
    const { className = '' } = this.props;
    const { showConfirmation, showError, inputValue, isBusy } = this.state;

    return (
      <div className={classNames(styles.wrapper, { [className]: !!className })}>
        {showConfirmation ? (
          <>
            <Text variant="titleSmall" color="muted" className={styles.message}>
              Confirm you want to delete your account by typing "DELETE" below:
            </Text>
            <br />
            <br />
            <br />
            <div className={styles.container}>
              <input
                className={classNames(styles.inputField, { [styles.inputError]: showError })}
                value={inputValue}
                onChange={this.handleChange}
                placeholder="DELETE"
                disabled={isBusy}
              />
              <span className={styles.placeholder}></span>
              <Button variant="text" size="large" disabled={isBusy} onClick={this.closeConfirmation}>
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button color="error" isBusy={isBusy} onClick={this.handleDelete}>
                Delete
              </Button>
            </div>
          </>
        ) : (
          <>
            <span className={styles.placeholder}></span>
            <Text variant="titleSmall" color="error" onClick={this.openConfirmation}>
              Delete Account
            </Text>
          </>
        )}
      </div>
    );
  }
}

export default InlineConfirmationInput;
