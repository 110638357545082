import { JoyUser } from 'joy-core';

export const RESET_SESSION = 'RESET_SESSION';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const resetSession = () => ({ type: RESET_SESSION });

export const setCurrentUser = (user: JoyUser | null) => ({
  type: SET_CURRENT_USER,
  user
});
