import { Browser, OperatingSystem } from 'detect-browser';
import { SET_BROWSER, SET_SNACKBAR, SET_LOCATION } from './actions';

export interface BrowserInfo {
  name: Browser | null | 'bot' | 'node' | 'react-native';
  os: OperatingSystem | null | NodeJS.Platform;
  type: string | null;
  version: string | null;
}

export interface SnackbarType {
  open: boolean;
  label?: React.ReactNode;
  variant?: 'default' | 'info' | 'warning' | 'success' | 'error';
  delay?: number;
  autoClose?: boolean;
}

interface SystemState {
  browser: BrowserInfo | null;
  snackbar: SnackbarType | null;
  location: string;
  isMobile: boolean;
  isTablet: boolean;
  isSmallTablet: boolean;
  isLargeTablet: boolean;
}

const initialState: SystemState = {
  browser: null,
  snackbar: null,
  location: 'ZZ',
  isMobile: window.matchMedia('only screen and (max-width: 576px)').matches,
  isTablet: window.matchMedia('only screen and (max-width: 768px)').matches,
  isSmallTablet: window.matchMedia('only screen and (max-width: 1024px)').matches,
  isLargeTablet: window.matchMedia('only screen and (max-width: 1366px)').matches
};

export default function system(state = initialState, { type, ...payload }: any): SystemState {
  switch (type) {
    case SET_BROWSER:
      return {
        ...state,
        browser: payload.browser
      };
    case SET_SNACKBAR:
      return {
        ...state,
        snackbar: payload.snackbar
      };
    case SET_LOCATION:
      return {
        ...state,
        location: payload.location
      };
    default:
      return state;
  }
}
