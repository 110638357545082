import { JoyAnalyticsService, JoyAuthService, JoyDataService, JoyUploadService } from 'joy-core';
import { JoyCrmIntegrationService } from 'joy-crm';
import {
  initializeFirebase,
  FirebaseAnalyticsServiceDestination,
  FirebaseAuthServiceProvider,
  FirebaseCrmIntegrationServiceProvider,
  FirestoreDataServiceProvider,
  StorageUploadServiceProvider
} from 'joy-firebase-services';

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export function firebaseInit() {
  initializeFirebase(FIREBASE_CONFIG);
  JoyAnalyticsService.add(new FirebaseAnalyticsServiceDestination());
  JoyAuthService.initialize(new FirebaseAuthServiceProvider());
  JoyCrmIntegrationService.initialize(new FirebaseCrmIntegrationServiceProvider());
  JoyDataService.initialize(new FirestoreDataServiceProvider());
  JoyUploadService.initialize(new StorageUploadServiceProvider());
}
