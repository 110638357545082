import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'joy-ui/components';

import SnackbarTemplate from '@template/Overlays/SnackbarTemplate';
import { RootState } from '@utils/redux/store';
import { setSnackbar } from '@utils/redux/system/actions';

const mapStateToProps = ({ system }: RootState) => ({
  snackbar: system.snackbar
});

const mapDispatchToProps = {
  setSnackbar
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux {}

// TODO: handle multiple instances
class Snackbar extends Component<IProps> {
  private closeTimer: NodeJS.Timeout | null = null;

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.snackbar === null && this.props.snackbar !== null) {
      const {
        snackbar: { delay = 8000, autoClose = true }
      } = this.props;

      if (this.closeTimer) {
        clearTimeout(this.closeTimer);
      }

      if (autoClose) {
        this.closeTimer = setTimeout(this.handleClose, delay);
      }
    }
  }

  handleClose = () => {
    this.props.setSnackbar(null);
  };

  render() {
    const { snackbar } = this.props;

    if (snackbar) {
      const { open, label, variant } = snackbar;

      return (
        <SnackbarTemplate
          open={open}
          label={label}
          actions={
            <Button color="white" variant="transparent" onClick={this.handleClose}>
              Close
            </Button>
          }
          variant={variant}
        />
      );
    }

    return null;
  }
}

export default connector(Snackbar);
