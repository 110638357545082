import { BrowserInfo, SnackbarType } from './reducers';

export const SET_BROWSER = 'SET_BROWSER';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_LOCATION = 'SET_LOCATION';

export const setBrowser = (browser: BrowserInfo | null) => ({
  type: SET_BROWSER,
  browser
});

export const setSnackbar = (snackbar: SnackbarType | null) => ({
  type: SET_SNACKBAR,
  snackbar
});

export const setUserLocation = (location: string | null) => ({
  type: SET_LOCATION,
  location
});
