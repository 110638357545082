import React, { Component } from 'react';
import classNames from 'classnames';
import { ModalBase } from 'joy-ui/components';

import styles from './SnackbarTemplate.module.scss';

interface IProps {
  label?: React.ReactNode;
  actions?: React.ReactNode;
  variant?: 'default' | 'info' | 'warning' | 'success' | 'error';
  open: boolean;
  progress?: number;
}

class SnackbarTemplate extends Component<IProps> {
  render() {
    const { label, actions, variant = 'default', open, progress } = this.props;

    if (!open) {
      return null;
    }

    return (
      <ModalBase basic={false} clickPassthrough>
        <div className={classNames(styles.wrapper, styles[`${variant}Color`])}>
          <div className={styles.content}>
            <div className={styles.label}>{label}</div>
            <div className={styles.actions}>{actions}</div>
          </div>
          <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
        </div>
      </ModalBase>
    );
  }
}

export default SnackbarTemplate;
