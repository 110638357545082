import React from 'react';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { JoyLogo, BountyLogo } from 'joy-ui/components';

import { RootState } from '@utils/redux/store';
import styles from './Header.module.scss';

const mapStateToProps = ({ system }: RootState) => ({
  language: system.location,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux {
  content?: React.ReactNode;
  leftContent?: React.ReactNode;
  middleContent?: React.ReactNode;
  hideLogo?: boolean;
  logoExtension?: 'portrait' | null;
  useLocation?: boolean;
  fixedHeader?: boolean;
  className?: string;
}

function Header({
  content,
  leftContent,
  middleContent,
  hideLogo = false,
  logoExtension,
  useLocation = false,
  fixedHeader = false,
  language,
  isMobile,
  className = ''
}: IProps) {
  return (
    <div className={classNames(styles.wrapper, { [styles.fixedHeader]: fixedHeader, [className]: Boolean(className) })}>
      <div className={classNames(styles.container, { [styles.containerMobile]: isMobile })}>
        <div className={styles.beforeLogo}>{leftContent}</div>

        {hideLogo || (
          <a href={process.env.REACT_APP_THIS_HOST}>
            {useLocation ? (
              language === 'US' ? (
                <JoyLogo extension={logoExtension} height={isMobile ? 20 : 32} />
              ) : (
                <BountyLogo height={isMobile ? 30 : 42} />
              )
            ) : (
              <JoyLogo extension={logoExtension} height={isMobile ? 20 : 32} />
            )}
          </a>
        )}

        <div className={styles.middleContent}>{middleContent}</div>

        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
}

export default connector(Header);
