import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { isEmail, JoyAnalyticsServiceEventAction, JoyLogHelper, JoyTrackHelper } from 'joy-core';
import { Input, EmailInput, PasswordInput, Text } from 'joy-ui/components';

import FormPageTemplate from '@template/Pages/FormPageTemplate';
import AuthHelper from '@helpers/AuthHelper';
import SnackbarHelper from '@helpers/SnackbarHelper';
import { ROUTES } from '@utils/system';

interface IProps extends RouteComponentProps {}

interface IState {
  name: string;
  nameError: string;
  email: string;
  emailError: string;
  password: string;
  passwordError: string;
  isBusy: boolean;
  lockEmail: boolean;
  redirectAtLogin: string | null;
}

class SignUp extends Component<IProps | any, IState> {
  constructor(props: IProps | any) {
    super(props);

    this.state = {
      name: '',
      nameError: '',
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      isBusy: false,
      lockEmail: false,
      redirectAtLogin: null
    };
  }

  componentDidMount() {
    const {
      location: {
        state: { email, lockEmail, redirectAtLogin }
      },
      history
    } = this.props;

    JoyTrackHelper.sendScreensEvent('create_account');

    if (redirectAtLogin) {
      this.setState({ redirectAtLogin });
    }

    if (email) {
      this.setState({ email, lockEmail });
    } else {
      history.push(ROUTES.signIn);
    }
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState({ [name]: value, [`${name}Error`]: '' } as Pick<IState, 'name' | 'email' | 'password'>);
  };

  handleSubmit = () => {
    this.setState({ isBusy: true }, async () => {
      if (this.checkFormErrors()) {
        const { name, email, password } = this.state;
        const result = await AuthHelper.checkUserExists(email);

        try {
          if (result?.exists && result.isAnonymous === false) {
            this.setState({ isBusy: false, emailError: 'Email already used' });
          } else {
            const newUser = await AuthHelper.createUser({ name, primaryEmail: email, password });

            if (newUser) {
              const loginResult = await AuthHelper.signIn(email, password);

              if (loginResult) {
                JoyTrackHelper.sendUsersEvent('joy', JoyAnalyticsServiceEventAction.create, !!result?.isAnonymous);

                this.props.history.push({ pathname: ROUTES.onboarding, state: { from: 'signUp' } });
              } else {
                this.setState({ isBusy: false });
              }
            } else {
              SnackbarHelper.showError('Server communication failed!');
              this.setState({ isBusy: false });
            }
          }
        } catch (error) {
          JoyLogHelper.error({ error, name, email, place: 'SignUp.handleSubmit' });
        }
      } else {
        this.setState({ isBusy: false });
      }
    });
  };

  checkFormErrors(): boolean {
    const { name, email, password } = this.state;
    const newState: any = {};

    if (name.length === 0) {
      newState.nameError = 'Cannot be blank';
    }

    if (email.length === 0) {
      newState.emailError = 'Cannot be blank';
    } else if (isEmail(email) === false) {
      newState.emailError = 'Email address is not valid';
    }

    if (password.length === 0) {
      newState.passwordError = 'Cannot be blank';
    } else if (password.length < 7) {
      newState.passwordError = 'Cannot be shorter than 7 characters';
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
      return false;
    }

    return true;
  }

  get legend() {
    return <Text>Create your Joy account.</Text>;
  }

  render() {
    const { name, nameError, email, emailError, password, passwordError, isBusy, lockEmail } = this.state;

    return (
      <FormPageTemplate legend={this.legend} submitText="Create account" isBusy={isBusy} onSubmit={this.handleSubmit}>
        <EmailInput name="email" value={email} error={emailError} disabled={lockEmail} onChange={this.handleChange} />

        <Input
          name="name"
          label="Name"
          placeholder="Your name"
          value={name}
          error={nameError}
          onChange={this.handleChange}
        />

        <PasswordInput
          name="password"
          placeholder="Create a password"
          value={password}
          error={passwordError}
          onChange={this.handleChange}
        />
      </FormPageTemplate>
    );
  }
}

export default SignUp;
