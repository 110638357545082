import { Dispatch } from 'redux';

import { JoyMedium } from 'joy-core';
import { JoyDeal, JoyPhotosession } from 'joy-crm';
import { ClientItemType } from '@utils/types';

export const RESET_CLIENT = 'RESET_CLIENT';
export const SET_CURRENT_DEAL = 'SET_CURRENT_DEAL';
export const SET_CURRENT_PHOTOSESSION = 'SET_CURRENT_PHOTOSESSION';
export const SET_CURRENT_PHOTOSESSION_MEDIA = 'SET_CURRENT_PHOTOSESSION_MEDIA';
export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';

export const resetClient = () => ({ type: RESET_CLIENT });

export const setCurrentDeal = (currentDeal: JoyDeal | null) => (
  dispatch: Dispatch<{ type: string; currentDeal: JoyDeal | null }>
) =>
  dispatch({
    type: SET_CURRENT_DEAL,
    currentDeal
  });

export const setCurrentPhotosession = (currentPhotosession: JoyPhotosession | null) => (
  dispatch: Dispatch<{ type: string; currentPhotosession: JoyPhotosession | null }>
) =>
  dispatch({
    type: SET_CURRENT_PHOTOSESSION,
    currentPhotosession
  });

export const setCurrentPhotosessionMedia = (currentPhotosessionMedia: Array<JoyMedium>) => (
  dispatch: Dispatch<{ type: string; currentPhotosessionMedia: Array<JoyMedium> }>
) =>
  dispatch({
    type: SET_CURRENT_PHOTOSESSION_MEDIA,
    currentPhotosessionMedia
  });

export const setClients = (clients: Array<ClientItemType>) => (
  dispatch: Dispatch<{ type: string; clients: Array<ClientItemType> }>
) =>
  dispatch({
    type: SET_CLIENTS,
    clients
  });

export const setSelectedEmail = (selectedEmail: string) => (
  dispatch: Dispatch<{ type: string; selectedEmail: string }>
) =>
  dispatch({
    type: SET_SELECTED_EMAIL,
    selectedEmail
  });
