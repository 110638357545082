import React, { Component } from 'react';
import classNames from 'classnames';
import { JoyLogo, Button } from 'joy-ui/components';

import styles from './FormPageTemplate.module.scss';

interface IProps {
  children?: React.ReactNode;
  pageExtension?: React.ReactNode;
  legend?: React.ReactNode;
  cardActionsExtension?: React.ReactNode;
  submitText?: string;
  isBusy?: boolean;
  onSubmit?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent) => void;
}

class FormPageTemplate extends Component<IProps> {
  handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const { onSubmit } = this.props;

      if (onSubmit) {
        onSubmit(event);
      }
    }
  };

  render() {
    const { children, pageExtension, legend, cardActionsExtension, submitText, isBusy = false, onSubmit } = this.props;
    const showSubmit = submitText && onSubmit;

    return (
      <div className={styles.wrapper}>
        <div className={styles.card} onKeyDownCapture={this.handleKeyDown}>
          <a href={process.env.REACT_APP_JOY_LINK}>
            <JoyLogo />
          </a>

          <div className={styles.legend}>{legend}</div>

          {children}

          <div className={styles.cardActions}>
            <div className={classNames({ [styles.cardActionsExtension]: showSubmit })}>{cardActionsExtension}</div>

            {showSubmit && (
              <Button color="secondary" onClick={onSubmit} isBusy={isBusy}>
                {submitText}
              </Button>
            )}
          </div>
        </div>

        <div className={styles.underCardExtension}>{pageExtension}</div>
      </div>
    );
  }
}

export default FormPageTemplate;
