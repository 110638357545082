import React, { Component } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import styles from './MultiEmailInput.module.scss';

interface IProps {
  emails: Array<string>;
  onChange: (emails: Array<string>) => void;
}

export default class MultiEmailInput extends Component<IProps> {
  getLabel(email: string, index: number, removeEmail: (index: number) => void) {
    return (
      <div data-tag key={index}>
        <div data-tag-item>{email}</div>
        <span data-tag-handle onClick={() => removeEmail(index)}>
          ×
        </span>
      </div>
    );
  }

  render() {
    const { emails, onChange } = this.props;

    return (
      <>
        <ReactMultiEmail
          className={styles.wrapper}
          placeholder="Enter an email"
          emails={emails}
          onChange={onChange}
          getLabel={this.getLabel}
        />

        <label className={styles.label}>Enter an email</label>
      </>
    );
  }
}
