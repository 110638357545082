import { detect } from 'detect-browser';
import { JoyField, JoyLogHelper } from 'joy-core';
import { JoyCrmIntegrationService, JoyCrmIntegrationServiceFunction } from 'joy-crm';

import store from '@utils/redux/store';
import { setBrowser, setUserLocation } from '@utils/redux/system/actions';

export const ROUTES = {
  home: '/',
  homeGroup: '/group/:groupId',
  homeGroupMedia: '/group/:groupId/media/:mediaId',
  homeGroupAlbum: '/group/:groupId/album/:albumId',
  homeGroupAlbumMedia: '/group/:groupId/album/:albumId/media/:mediaId',

  accountInfo: '/account/info',
  accountPayment: '/account/payment',
  accountSubscription: '/account/subscription',
  accountOffers: '/account/offers',

  signIn: '/sign-in',
  signUp: '/sign-up',
  verify: '/verify',
  resetPassword: '/reset',
  forgotPassword: '/forgot-password',
  onboarding: '/onboarding',
  error404: '/404'
};

export async function systemInit() {
  const browser = detect();

  if (browser) {
    const { name, os, type, version } = browser;

    store.dispatch(setBrowser({ name, os, type, version }));
  }

  try {
    const location = await JoyCrmIntegrationService.instance.call(JoyCrmIntegrationServiceFunction.userLocation);

    store.dispatch(setUserLocation(location[JoyField.countryCode]));
  } catch (error) {
    JoyLogHelper.error({ error, place: 'system.systemInit' });
    console.log('System initialization error.');
  }
}
