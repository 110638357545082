import React, { Component } from 'react';
import {
  isEmail,
  isPhone,
  JoyField,
  JoyUploadService,
  JoyUploadServiceUploadType,
  JoyUser,
  JoyLogHelper
} from 'joy-core';
import { Text, Input, Button, EmailInput, FileUploadWrapper, Avatar } from 'joy-ui';

import ModalTemplate from '@template/Overlays/ModalTemplate';
import SnackbarHelper from '@helpers/SnackbarHelper';
import DatePicker from '@components/DatePicker';

import styles from './EditAccount.module.scss';

interface IProps {
  open: boolean;
  user: JoyUser | null;
  onClose: () => void;
  onSubmit: () => void;
}

interface IState {
  isBusy: boolean;
  name: string;
  nameError: string;
  email: string;
  emailError: string;
  phone: string;
  phoneError: string;
  birthday: Date;
  birthdayError: string;
  uploadingPhoto: boolean;
}

class EditAccount extends Component<IProps, IState> {
  maxDate: Date = new Date(2011, 1, 1);
  minDate: Date = new Date(1900, 1, 1);

  constructor(props: IProps) {
    super(props);

    const { user } = props;
    let { name = '', primaryEmail = '', phone = '', birthday = this.maxDate } = user || {};

    this.state = {
      isBusy: false,
      name,
      nameError: '',
      email: primaryEmail,
      emailError: '',
      phone,
      phoneError: '',
      birthday,
      birthdayError: '',
      uploadingPhoto: false
    };
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState({ [name]: value, [`${name}Error`]: '' } as Pick<IState, 'name' | 'email' | 'phone'>);
  };

  handleDateUpdate = (newDate: Date) => {
    this.setState({ birthday: newDate });
  };

  handleClose = () => {
    const { user } = this.props;
    let { name = '', primaryEmail = '', phone = '', birthday = this.maxDate } = user || {};

    this.setState(
      {
        isBusy: false,
        name,
        nameError: '',
        email: primaryEmail,
        emailError: '',
        phone,
        phoneError: '',
        birthday,
        birthdayError: '',
        uploadingPhoto: false
      },
      this.props.onClose
    );
  };

  handleProfilePhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { user } = this.props;
    const files = event.currentTarget.files;

    if (user && files) {
      const avatar = files[0];

      this.setState({ uploadingPhoto: true }, async () => {
        try {
          JoyUploadService.instance.upload(
            JoyUploadServiceUploadType.avatar,
            {
              [JoyField.user]: user.id,
              [JoyField.data]: await avatar.arrayBuffer()
            },
            async (result) => {
              if (typeof result === 'string') {
                await user.setPhotoUrl(result);

                setTimeout(() => {
                  this.setState({ uploadingPhoto: false });
                }, 500);
              } else {
                throw result;
              }
            },
            (progress: number) => {
              // console.log(progress);
            }
          );
        } catch (error) {
          JoyLogHelper.error({ error, userId: user.id, place: 'EditAccount.handleProfilePhoto' });

          this.setState({ uploadingPhoto: false });

          SnackbarHelper.showError('Could not update profile picture!');
        }
      });
    }
  };

  handleUpdateAccount = () => {
    this.setState({ isBusy: true }, async () => {
      const { user, onSubmit } = this.props;

      if (this.checkFormErrors() && user) {
        const { name, email, phone, birthday } = this.state;

        try {
          await user.setName(name);
          await user.setPrimaryEmail(email);
          await user.setPhone(phone);

          if (birthday !== null) {
            await user.setBirthday(birthday);
          }

          onSubmit();
        } catch (error) {
          JoyLogHelper.error({
            error,
            userId: user.id,
            data: { name, email, phone, birthday },
            place: 'EditAccount.handleUpdateAccount'
          });

          SnackbarHelper.showError('Could not update user profile! Server communication failed!');
        }
      }

      this.setState({ isBusy: false });
    });
  };

  checkFormErrors(): boolean {
    const { name, email, phone } = this.state;
    const newState: any = {};

    if (name.length === 0) {
      newState.nameError = 'Cannot be blank';
    }

    if (email.length === 0) {
      newState.emailError = 'Cannot be blank';
    } else if (isEmail(email) === false) {
      newState.emailError = 'Email address is not valid';
    }

    if (phone !== null && phone?.length !== 0) {
      // if (phone?.length === 0) {
      //   newState.phoneError = 'Cannot be blank';
      // } else
      if (isPhone(phone) === false) {
        newState.phoneError = 'Phone number is not valid';
      }
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
      return false;
    }

    return true;
  }

  render() {
    const {
      state: { isBusy, name, nameError, email, emailError, phone, phoneError, birthday, birthdayError, uploadingPhoto },
      props: { open, user }
    } = this;

    return (
      <ModalTemplate
        open={open}
        legend={<Text variant="heading">Account Information</Text>}
        onClose={this.handleClose}
        cardActions={
          <div style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
            <Button color="secondary" isBusy={isBusy} size="large" onClick={this.handleUpdateAccount}>
              Save changes
            </Button>
            <br />
            <Button variant="text" disabled={isBusy} size="large" onClick={this.handleClose}>
              Cancel
            </Button>
          </div>
        }
      >
        <FileUploadWrapper name="profilePhoto" className={styles.avatar} onChange={this.handleProfilePhoto}>
          <Avatar name={user?.name || ''} photoUrl={user?.photoUrl} size={100} isBusy={uploadingPhoto} />
          <Text variant="body">Edit</Text>
        </FileUploadWrapper>

        <Input
          name="name"
          label="Display Name"
          placeholder="Display Name"
          defaultValue={name}
          error={nameError}
          onChange={this.handleChange}
        />

        <EmailInput
          name="email"
          label="Email Address"
          placeholder="Email Address"
          defaultValue={email}
          error={emailError}
          onChange={this.handleChange}
        />

        <Input
          name="phone"
          label="Phone Number"
          placeholder="Phone Number"
          defaultValue={phone}
          error={phoneError}
          onChange={this.handleChange}
        />

        <DatePicker
          id="userBirthday"
          value={birthday}
          minDate={this.minDate}
          maxDate={this.maxDate}
          onChange={this.handleDateUpdate}
        />
      </ModalTemplate>
    );
  }
}

export default EditAccount;
