import React, { Component } from 'react';
// import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { JoyLogHelper } from 'joy-core';
import { LoadingLoop, Text, DropDownItem } from 'joy-ui';

import { RootState } from '@utils/redux/store';
import { setSelectedItems, clearSelectedItems } from '@utils/redux/media/actions';
import { ROUTES } from '@utils/system';
import SnackbarHelper from '@helpers/SnackbarHelper';
import MediaGrid from '../MediaGrid';
import MediaModal from '../MediaModal';

import styles from './MediaContainer.module.scss';

const mapStateToProps = ({ media }: RootState) => ({
  currentGroup: media.currentGroup,
  currentAlbum: media.currentAlbum,
  currentMedia: media.currentMedia,
  selectedItems: media.selectedItems
});

const mapDispatchToProps = { setSelectedItems, clearSelectedItems };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// interface MatchParams {
//   clientId?: string;
//   mediaId?: string;
// }

// interface IProps extends PropsFromRedux, RouteComponentProps<MatchParams> {
interface IProps extends PropsFromRedux {
  loading: boolean;
  selectedId?: string;
  modalCloseUrl: string;
  onDeleteMedia?: (mediaId: string) => void;
  onSetCover?: () => void;
}

interface IState {
  busyItems: Array<string>;
}

class MediaContainer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      busyItems: []
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const { currentGroup: prevGroup, currentAlbum: prevAlbum } = prevProps;
    const { currentGroup, currentAlbum, clearSelectedItems } = this.props;

    if ((currentGroup && currentGroup.id !== prevGroup?.id) || currentAlbum?.id !== prevAlbum?.id) {
      clearSelectedItems();
    }
  }

  generateItemUrl = (mediaId: string | undefined): string => {
    const { currentGroup, currentAlbum } = this.props;

    if (currentGroup && mediaId) {
      if (currentAlbum) {
        return ROUTES.homeGroupAlbumMedia
          .replace(':groupId', currentGroup.id)
          .replace(':albumId', currentAlbum.id)
          .replace(':mediaId', mediaId);
      }

      return ROUTES.homeGroupMedia.replace(':groupId', currentGroup.id).replace(':mediaId', mediaId);
    }

    return '';
  };

  handleMediaSelect = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();

    const { setSelectedItems } = this.props;
    const itemId = event.currentTarget.getAttribute('data-value') || '';

    setSelectedItems(itemId);
  };

  get mediaItemInfo() {
    const { selectedId, currentMedia: media } = this.props;

    let itemIndex = media.findIndex((item) => item.id === selectedId);

    let prevUrl = '',
      nextUrl = '',
      itemsLength = media.length;

    if (itemsLength > 1) {
      prevUrl = this.generateItemUrl(media[itemIndex - 1]?.id || media[itemsLength - 1].id);
      nextUrl = this.generateItemUrl(media[itemIndex + 1]?.id || media[0].id);
    }

    return { item: media[itemIndex], prevUrl, nextUrl };
  }

  handleMediaDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const mediaId = event.currentTarget.dataset.value;
    const { onDeleteMedia } = this.props;

    if (onDeleteMedia && mediaId) {
      onDeleteMedia(mediaId);
    }
  };

  itemOptions = (itemId: string) => {
    if (this.props.currentAlbum) {
      return (
        <React.Fragment key="options">
          <DropDownItem label="Set as cover" data-value={itemId} onClick={this.handleSetCover} />
        </React.Fragment>
      );
    }

    return null;
  };

  handleSetCover = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const { currentAlbum, currentMedia, onSetCover } = this.props;
    const mediaId = event.currentTarget.dataset.value || '';

    this.setState(
      (prevState) => ({ busyItems: [...prevState.busyItems, mediaId] }),
      async () => {
        const media = currentMedia.find((item) => item.id === mediaId);

        try {
          if (currentAlbum && media) {
            await currentAlbum.setCoverMedium(media);
          } else {
            throw Error('Item not found');
          }
        } catch (error) {
          JoyLogHelper.error({
            error,
            groupId: currentAlbum?.groupId,
            albumId: currentAlbum?.id,
            mediaId: media?.id,
            place: 'MediaContainer.handleSetCover'
          });

          SnackbarHelper.showError('Could not update album cover!');
        } finally {
          if (onSetCover) {
            onSetCover();
          } else {
            this.setState((prevState) => ({ busyItems: prevState.busyItems.filter((item) => item !== mediaId) }));
          }
        }
      }
    );
  };

  render() {
    const { busyItems } = this.state;
    const { currentMedia, loading, selectedId, selectedItems, modalCloseUrl } = this.props;

    return (
      <div className={styles.wrapper}>
        <Text variant="bodySmall" color="muted" className={styles.title}>
          MEDIA
        </Text>

        {loading ? (
          <LoadingLoop color="muted" className={styles.loadingLoop} />
        ) : (
          <div className={styles.listContainer}>
            <MediaGrid
              items={currentMedia}
              busyItems={busyItems}
              selectedItems={selectedItems}
              onSelect={this.handleMediaSelect}
              onDelete={this.handleMediaDelete}
              showSelected={selectedItems.length > 0}
              generateItemUrl={this.generateItemUrl}
              itemOptions={this.itemOptions}
            />
          </div>
        )}

        <MediaModal open={!!selectedId} mediaItem={this.mediaItemInfo} modalCloseUrl={modalCloseUrl} />
      </div>
    );
  }
}

export default connector(MediaContainer);
