import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from '@template/App';
import store from '@utils/redux/store';
import { firebaseInit } from '@utils/firebase';
import { mixpanelInit } from '@utils/mixpanel';
import { bugfenderInit } from '@utils/bugfender';
import { systemInit } from '@utils/system';

import 'joy-ui/styles/fonts.scss';
import 'joy-ui/styles/globalStyle.scss';

firebaseInit();

mixpanelInit();

bugfenderInit();

systemInit();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app-root')
);
