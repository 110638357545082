import { JoyMedium, JoyMediumsObserver, JoyObjectObserver, JoyTask } from 'joy-core';
import {
  RESET_UPLOAD,
  SET_UPLOAD_COUNT,
  SET_UPLOADED_COUNT,
  ADD_TO_UPLOADED_COUNT,
  SET_UPLOADING_ITEMS,
  REMOVE_UPLOADING_ITEMS,
  REMOVE_UPLOADING_ITEM,
  SET_UPLOADING_TASKS,
  REMOVE_UPLOADING_TASK,
  SET_UPLOAD_OBSERVER,
  SET_UPLOAD_SESSION,
  REMOVE_UPLOAD_CLIENT
} from './actions';

export interface UploadingItemsType {
  [uploadItem: string]: UploadingItemType;
}

export interface UploadingTasksType {
  [uploadTask: string]: JoyTask;
}

export interface UploadingItemType {
  thumbnailUrl: string;
  type: 'image' | 'video' | 'other';
  name: string;
  status: string;
  medium?: JoyMedium | null;
  observer?: JoyObjectObserver | null;
  progress?: number;
}

export interface UploadClientType {
  uploadCount: number;
  uploadedCount: number;
  uploadingItems: UploadingItemsType | null;
  uploadingTasks: UploadingTasksType | null;
  uploadObserver: JoyMediumsObserver | null;
  uploadSessions: Array<string>;
}

export interface UploadState {
  [uploadClient: string]: UploadClientType;
}

interface Payload extends UploadClientType {
  type: string;
  clientId: string;
  itemId: string;
  taskId: string;
  uploadSession: string;
}

const initialState: UploadState = {};

export default function upload(state = initialState, { type, clientId, ...payload }: Payload): UploadState {
  switch (type) {
    case RESET_UPLOAD:
      return initialState;
    case SET_UPLOAD_COUNT:
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          uploadCount: payload.uploadCount
        }
      };
    case SET_UPLOADED_COUNT:
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          uploadedCount: payload.uploadedCount
        }
      };
    case ADD_TO_UPLOADED_COUNT:
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          uploadedCount: state[clientId].uploadedCount + 1
        }
      };
    case SET_UPLOADING_ITEMS:
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          uploadingItems: { ...state[clientId].uploadingItems, ...payload.uploadingItems }
        }
      };
    case REMOVE_UPLOADING_ITEMS:
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          uploadingItems: null
        }
      };
    case REMOVE_UPLOADING_ITEM:
      let newState = { ...state };

      try {
        let uploadingItems = { ...newState[clientId].uploadingItems };
        delete uploadingItems[payload.itemId];

        newState[clientId] = {
          ...newState[clientId],
          uploadingItems
        };
      } catch (error) {}

      return newState;
    case SET_UPLOADING_TASKS:
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          uploadingTasks: { ...state[clientId].uploadingTasks, ...payload.uploadingTasks }
        }
      };
    case REMOVE_UPLOADING_TASK:
      let uploadingTasks = { ...state[clientId].uploadingTasks };
      delete uploadingTasks[payload.taskId];

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          uploadingTasks
        }
      };
    case SET_UPLOAD_OBSERVER:
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          uploadObserver: payload.uploadObserver
        }
      };
    case SET_UPLOAD_SESSION:
      let uploadSessions = state[clientId].uploadSessions || [];

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          uploadSessions: [...uploadSessions, payload.uploadSession]
        }
      };
    case REMOVE_UPLOAD_CLIENT:
      let uploadState = { ...state };
      delete uploadState[clientId];

      return uploadState;
    default:
      return state;
  }
}
