import { JoyLoggingService } from 'joy-core';
import { BugfenderLoggingServiceDestination } from 'joy-bugfender-services';

const BUGFENDER_TOKEN = process.env.REACT_APP_BUGFENDER_TOKEN;

export function bugfenderInit() {
  if (BUGFENDER_TOKEN) {
    const instance = new BugfenderLoggingServiceDestination(BUGFENDER_TOKEN, {
      overrideConsoleMethods: false,
      version: process.env.REACT_APP_VERSION,
      logUIEvents: false,
      logBrowserEvents: false,
      printToConsole: false
    });

    if (instance) {
      JoyLoggingService.add(instance);
    }
  } else {
    console.log('Could not initialize bugfender service!');
  }
}
