import { JoyMedium } from 'joy-core';
import { JoyDeal, JoyPhotosession } from 'joy-crm';

import { ClientItemType } from '@utils/types';
import {
  RESET_CLIENT,
  SET_CURRENT_DEAL,
  SET_CURRENT_PHOTOSESSION,
  SET_CURRENT_PHOTOSESSION_MEDIA,
  SET_CLIENTS,
  SET_SELECTED_EMAIL
} from './actions';

interface ClientState {
  currentDeal: JoyDeal | null;
  currentPhotosession: JoyPhotosession | null;
  currentPhotosessionMedia: Array<JoyMedium>;
  clients: Array<ClientItemType>;
  selectedEmail: string;
}

const initialState: ClientState = {
  currentDeal: null,
  currentPhotosession: null,
  currentPhotosessionMedia: [],
  clients: [],
  selectedEmail: ''
};

export default function client(state = initialState, { type, ...payload }: any): ClientState {
  switch (type) {
    case RESET_CLIENT:
      return initialState;
    case SET_CURRENT_DEAL:
      return {
        ...state,
        currentDeal: payload.currentDeal
      };
    case SET_CURRENT_PHOTOSESSION:
      return {
        ...state,
        currentPhotosession: payload.currentPhotosession
      };
    case SET_CURRENT_PHOTOSESSION_MEDIA:
      return {
        ...state,
        currentPhotosessionMedia: payload.currentPhotosessionMedia
      };
    case SET_CLIENTS:
      return {
        ...state,
        clients: payload.clients
      };
    case SET_SELECTED_EMAIL:
      return {
        ...state,
        selectedEmail: payload.selectedEmail
      };
    default:
      return state;
  }
}
