import { JoyLogo, Text } from 'joy-ui';
import googleStore from 'joy-ui/assets/img/android-app-store.png';
import appleStore from 'joy-ui/assets/img/apple-app-store.png';

import styles from './GetApp.module.scss';

interface IProps {
  os: string;
}

export default function GetApp({ os }: IProps) {
  let storeAction;
  switch (os) {
    case 'Android OS':
      storeAction = (
        <a href={process.env.REACT_APP_ANDROID_STORE_LINK}>
          <img src={googleStore} alt="play store" height={50} />
        </a>
      );
      break;
    case 'iOS':
      storeAction = (
        <a href={process.env.REACT_APP_IOS_STORE_LINK}>
          <img src={appleStore} alt="app store" height={50} />
        </a>
      );
      break;
    default:
      storeAction = (
        <>
          <a href={process.env.REACT_APP_ANDROID_STORE_LINK}>
            <img src={googleStore} alt="play store" height={50} />
          </a>
          &nbsp;&nbsp;
          <a href={process.env.REACT_APP_IOS_STORE_LINK}>
            <img src={appleStore} alt="app store" height={50} />
          </a>
        </>
      );
      break;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <JoyLogo color="fullWhite" height={26} />
      </div>

      <Text className={styles.heading} variant="heading">
        Get the Joy app
      </Text>

      <Text className={styles.content} variant="titleSmall">
        Download the Joy app to add photos and create albums of your own.
      </Text>

      <div className={styles.storeActions}>{storeAction}</div>
    </div>
  );
}
