import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';
import { Button, LoadingLoop, Text } from 'joy-ui/components';

import NavigationViewTemplate from '@template/Pages/NavigationViewTemplate';
import { RootState } from '@utils/redux/store';
import styles from './AccountManagement.module.scss';
import { ROUTES } from '@utils/system';
import InfoContent from './InfoContent';

const mapStateToProps = ({ user, system }: RootState) => ({
  currentUser: user.currentUser,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux, RouteComponentProps {}

interface IState {}

class AccountManagement extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  get sideContent() {
    switch (this.props.location.pathname) {
      case ROUTES.accountInfo:
        return <InfoContent />;
      default:
        return <div className={styles.sideContent}>not implemented</div>;
    }
  }

  render() {
    // const {} = this.state;
    const {
      location: { pathname }
    } = this.props;

    return (
      <NavigationViewTemplate
        headerContent={
          <>
            {false && (
              <Button color="primary" size="large">
                Add memories
              </Button>
            )}
          </>
        }
        openDrawerAtStart={false}
        fixedHeader
      >
        {false ? (
          <LoadingLoop color="disabled" />
        ) : (
          <div className={styles.container}>
            <div className={styles.navigation}>
              <Link className={styles.itemLink} to={ROUTES.accountInfo}>
                <Text
                  className={classNames(styles.itemLinkText, {
                    [styles.itemLinkSelected]: pathname === ROUTES.accountInfo
                  })}
                  variant="subtitle"
                >
                  Account
                </Text>
              </Link>

              {/* <Link className={styles.itemLink} to={ROUTES.accountPayment}>
                <Text
                  className={classNames(styles.itemLinkText, {
                    [styles.itemLinkSelected]: pathname === ROUTES.accountPayment
                  })}
                  variant="subtitle"
                >
                  Payment
                </Text>
              </Link>

              <Link className={styles.itemLink} to={ROUTES.accountSubscription}>
                <Text
                  className={classNames(styles.itemLinkText, {
                    [styles.itemLinkSelected]: pathname === ROUTES.accountSubscription
                  })}
                  variant="subtitle"
                >
                  Subscription
                </Text>
              </Link>

              <Link className={styles.itemLink} to={ROUTES.accountOffers}>
                <Text
                  className={classNames(styles.itemLinkText, {
                    [styles.itemLinkSelected]: pathname === ROUTES.accountOffers
                  })}
                  variant="subtitle"
                >
                  Offers
                </Text>
              </Link> */}
            </div>

            {this.sideContent}
          </div>
        )}
      </NavigationViewTemplate>
    );
  }
}

export default connector(withRouter(AccountManagement));
