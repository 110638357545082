import { Dispatch } from 'redux';

import {
  JoyGroup,
  JoyAlbum,
  JoyMedium,
  JoyUser,
  JoyUserHelper,
  JoyProperties,
  JoyGroupHelper,
  JoyStatuses,
  JoyStatus,
  JoyAlbumHelper,
  JoyAlbumsObserver,
  JoyMediumsObserver,
  JoyMediumHelper,
  JoyUserRole
} from 'joy-core';
import ObserverHelper from '@helpers/ObserverHelper';
import { GroupsAvatarsType, PeopleAvatarsType } from './reducers';

export const RESET_MEDIA = 'RESET_MEDIA';
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';
export const SET_CURRENT_ALBUM = 'SET_CURRENT_ALBUM';
export const SET_CURRENT_MEDIA = 'SET_CURRENT_MEDIA';
export const SET_THUMBNAIL_MEDIA = 'SET_THUMBNAIL_MEDIA';
export const SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS';
export const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_LOADING_GROUPS = 'SET_LOADING_GROUPS';
export const SET_ALBUMS = 'SET_ALBUMS';
export const SET_ALBUMS_OBSERVER = 'SET_ALBUMS_OBSERVER';
export const SET_LOADING_ALBUMS = 'SET_LOADING_ALBUMS';
export const SET_MEDIUMS_OBSERVER = 'SET_MEDIUMS_OBSERVER';
export const SET_LOADING_MEDIA = 'SET_LOADING_MEDIA';
export const SET_PEOPLE = 'SET_PEOPLE';
export const SET_PEOPLE_AVATARS = 'SET_PEOPLE_AVATARS';
export const SET_LOADING_PEOPLE = 'SET_LOADING_PEOPLE';
export const SET_GROUPS_OWNERS = 'SET_GROUPS_OWNERS';

export const resetMedia = () => ({ type: RESET_MEDIA });

export const setGroups = (groups: Array<JoyGroup>) => ({
  type: SET_GROUPS,
  groups
});

const setLoadingGroups = (loadingGroups: boolean) => ({ type: SET_LOADING_GROUPS, loadingGroups });

export const getGroups =
  (userId: string, name: string | null = null, description: string | null = null, count: number = 0) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoadingGroups(true));

    const groups = await JoyUserHelper.getGroups(userId, name, description, count);

    for (const group of groups) {
      ObserverHelper.addGroupObserver(group);
    }

    await ObserverHelper.getGroupOwners(groups, userId);
    const result = dispatch(setGroups(groups));

    dispatch(setLoadingGroups(false));

    return result;
  };

export const setCurrentGroup = (currentGroup: JoyGroup | null) => (dispatch: Dispatch) => {
  ObserverHelper.disposeObservers(['albums', 'mediums']);

  if (currentGroup) {
    const albumsObserver = ObserverHelper.createAlbumsObserver(currentGroup.id);
    const mediumsObserver = ObserverHelper.createMediumsObserver(currentGroup.id);

    albumsObserver.setObservableCount(0);
    mediumsObserver.setObservableCount(0);

    dispatch(setAlbumsObserver(albumsObserver));
    dispatch(setMediumsObserver(mediumsObserver));
  } else {
    dispatch(setAlbumsObserver(null));
    dispatch(setMediumsObserver(null));
  }

  return dispatch({
    type: SET_CURRENT_GROUP,
    currentGroup
  });
};

export const setAlbums = (albums: Array<JoyAlbum>) => ({
  type: SET_ALBUMS,
  albums
});

const setAlbumsObserver = (albumsObserver: JoyAlbumsObserver | null) => ({
  type: SET_ALBUMS_OBSERVER,
  albumsObserver
});

const setLoadingAlbums = (loadingAlbums: boolean) => ({ type: SET_LOADING_ALBUMS, loadingAlbums });

export const getAlbums =
  (groupId: string, count: number = 0, properties?: JoyProperties, isPublic?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoadingAlbums(true));

    const albums = await JoyGroupHelper.getAlbums(groupId, count, properties, isPublic);

    const result = dispatch(setAlbums(albums));

    dispatch(setLoadingAlbums(false));

    return result;
  };

export const setCurrentAlbum = (currentAlbum: JoyAlbum | null) => (dispatch: Dispatch) => {
  ObserverHelper.disposeObservers(['mediums']);

  if (currentAlbum) {
    const mediumsObserver = ObserverHelper.createMediumsObserver('', currentAlbum.id);

    mediumsObserver.setObservableCount(0);

    dispatch(setMediumsObserver(mediumsObserver));
  } else {
    dispatch(setMediumsObserver(null));
  }

  return dispatch({
    type: SET_CURRENT_ALBUM,
    currentAlbum
  });
};

export const setCurrentMedia = (currentMedia: Array<JoyMedium>) => ({
  type: SET_CURRENT_MEDIA,
  currentMedia
});

const setMediumsObserver = (mediumsObserver: JoyMediumsObserver | null) => ({
  type: SET_MEDIUMS_OBSERVER,
  mediumsObserver
});

const setLoadingMedia = (loadingMedia: boolean) => ({ type: SET_LOADING_MEDIA, loadingMedia });

export const getGroupMedia =
  (groupId: string, count: number = 0, status: JoyStatuses, properties?: JoyProperties, isPublic?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoadingMedia(true));

    const media = await JoyGroupHelper.getMediums(groupId, count, status, properties, isPublic);

    ObserverHelper.disposeObservers(['mediums']);

    const mediumsObserver = ObserverHelper.createMediumsObserver(groupId);

    mediumsObserver.setObservableCount(0);

    dispatch(setMediumsObserver(mediumsObserver));

    const result = dispatch(setCurrentMedia(media));

    dispatch(setLoadingMedia(false));

    return result;
  };

export const getAlbumMedia =
  (
    albumId: string,
    groupId: string,
    count: number = 0,
    status: JoyStatuses,
    properties?: JoyProperties,
    isPublic?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoadingMedia(true));

    const media = await JoyAlbumHelper.getMediums(albumId, groupId, count, status, properties, isPublic);

    ObserverHelper.disposeObservers(['mediums']);

    const mediumsObserver = ObserverHelper.createMediumsObserver(groupId, albumId);

    mediumsObserver.setObservableCount(0);

    dispatch(setMediumsObserver(mediumsObserver));

    const result = dispatch(setCurrentMedia(media));

    dispatch(setLoadingMedia(false));

    return result;
  };

export const getThumbnailMedia = (groupId: string) => async (dispatch: Dispatch) => {
  let media = await JoyGroupHelper.getMediums(groupId, 4, [JoyStatus.processed]);

  if (!media) {
    media = [];
  }

  dispatch({
    type: SET_THUMBNAIL_MEDIA,
    thumbnailMedia: media
  });
};

export const deleteMedia = (groupId: string, mediumIds: Array<string>) => async (dispatch: Dispatch) => {
  dispatch(setLoadingMedia(true));

  for (const mediumId of mediumIds) {
    await JoyMediumHelper.delete(mediumId, groupId);
  }

  const result = dispatch({
    type: CLEAR_SELECTED_ITEMS
  });

  dispatch(setLoadingMedia(false));

  return result;
};

export const setSelectedItems = (mediaId: string) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_SELECTED_ITEMS,
    mediaId
  });

export const clearSelectedItems = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAR_SELECTED_ITEMS
  });

export const setPeople = (people: Array<JoyUser>) => ({
  type: SET_PEOPLE,
  people
});

const setLoadingPeople = (loadingPeople: boolean) => ({ type: SET_LOADING_PEOPLE, loadingPeople });

export const getPeople = (groupId: string) => async (dispatch: Dispatch) => {
  dispatch(setLoadingPeople(true));

  const people = await JoyGroupHelper.getUsers(groupId);

  const result = dispatch(setPeople(people));

  dispatch(setLoadingPeople(false));

  return result;
};

const setPeopleAvatars = (avatars: PeopleAvatarsType) => ({
  type: SET_PEOPLE_AVATARS,
  avatars
});

export const getPeopleAvatars = (groups: Array<JoyGroup>) => async (dispatch: Dispatch) => {
  const newAvatars = {};

  for (const group of groups) {
    let users = await JoyGroupHelper.getUsers(group.id);

    for (const user of users) {
      newAvatars[user.id] = { name: user.name, photoUrl: user.photoUrl };
    }
  }

  return dispatch(setPeopleAvatars(newAvatars));
};

const setGroupsOwners = (groupsOwners: GroupsAvatarsType) => ({
  type: SET_GROUPS_OWNERS,
  groupsOwners
});

export const getGroupsOwners = async (groups: Array<JoyGroup>, userId: string) => async (dispatch: Dispatch) => {
  const newOwners = {};

  for (const group of groups) {
    let users = await JoyGroupHelper.getUsers(group.id);

    for (const user of users) {
      if (user.groups[group.id] === JoyUserRole.owner) {
        if (user.id === userId) {
          newOwners[group.id] = 'You';
        } else {
          newOwners[group.id] = user.name;
        }
      }
    }
  }

  return dispatch(setGroupsOwners(newOwners));
};
